import {useState,useEffect} from 'react';
import './DemoConfirmation.css';
import './Dashboard.css';
import './DashboardOutputTypes.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, testGenMedia, addUserPreference, removeUserPreference, getJourneys, checkSubscriptionStatus, getUserOutputTypes, getAllJourneySteps, getAllJourneyStepsByOutputType, getAllJourneyStepsNotInOutputType } from '../../utils/api';
import { FaBrain, FaBriefcase, FaCalendarAlt, FaCaretLeft, FaCloud, FaLock, FaRegCalendarCheck, FaRegLightbulb, FaRegStar, FaSoundcloud, FaSpinner, FaStar, FaTimes, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FirstAssistant from './FirstAssistant';
import FadeIn from '../../utils/fadein';
import TypeWriter from './components/TypeWriter';
import imgLogo from '../../assets/design/yi_logo.png';
import * as FaIcons from 'react-icons/fa';


function DashboardOutputTypes() {
  const [referenceId, setReferenceId] = useState('');
  const [journeys, setJourneys] = useState([]);
  const [loadedJourneys, setLoadedJourneys] = useState(false);
  const [allSteps, setAllSteps] = useState([]);
  const [steps, setSteps] = useState([]);
  const [otherSteps, setOtherSteps] = useState([]);
  const [demo, setDemo] = useState({});
  const [onboardingStatus, setOnboardingStatus] = useState(false);
  const [loadedOnboarding, setLoadedOnboarding] = useState(false);
  const [preferredName, setPreferredName] = useState('');
  const search = useLocation().search;
  const [loaded, setLoaded] = useState(false);
  const [loadedOtherSteps, setLoadedOtherSteps] = useState(false);
  const [verified, setVerified] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showOnboarding1, setShowOnboarding1] = useState(false);
  const [showOnboarding2, setShowOnboarding2] = useState(false);
  const [showOnboarding3, setShowOnboarding3] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentJourneyId, setCurrentJourneyId] = useState(null);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [loadedSubscriptionCheck, setLoadedSubscriptionCheck] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState(0);
  const [outputTypes, setOutputTypes] = useState([]);
  const [selectedOutputType, setSelectedOutputType] = useState(null);
  const [selectedOutputTypeId, setSelectedOutputTypeId] = useState(null);
  const [showOther, setShowOther] = useState(false);
  const [showExamples, setShowExamples] = useState(false);
  const [examples, setExamples] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id'); // Retrieves the 'id' parameter
  const navigate = useNavigate();

    const IconDisplay = ({ iconName }) => {
      if (iconName == null || iconName.length == 0){
        return <p>No icon.</p>
      }
    
      // Dynamically get the icon component from the FaIcons object
      const IconComponent = FaIcons[iconName];
    
      // If the icon is found, render it
      if (IconComponent) {
        return <IconComponent />;
      }
    
      // Fallback if icon is not found
      return <p>Icon not found</p>;
    };

  useEffect(() => {
    async function loadSubscriptionCheck(){
      let res = await checkSubscriptionStatus();

      if (res.success){
        setHasSubscription(res.data);
        setLoadedSubscriptionCheck(true);
      }

      else{
        setHasSubscription(false);
        setLoadedSubscriptionCheck(true);
      }
    }

    async function loadOutputTypes(){
      let res = await getUserOutputTypes();

      if (res.success){
        setOutputTypes(res.data);
      }
    }

    async function loadJourneys(){
      let res = await getJourneys();
      
      if (res.success){
        setJourneys(res.data);
        setLoadedJourneys(true);

        if (id != null){
          setCurrentJourneyId(id);
          setSelectedJourney(res.data.filter(x => x.journey_id == id)[0])
        }
      }
    }

    async function checkOnboarding(){
      let res = await getOnboardingStatus();

      if (res.success){
        setOnboardingStatus(res.data);
        setLoadedOnboarding(true);
      }
    }

    async function loadSteps(){
      let res = await getAllJourneySteps();
      if (res.success){
        setAllSteps(res.data);
        setLoaded(true);
      }
    }

    loadOutputTypes();
    loadSubscriptionCheck();
    checkOnboarding();
    loadJourneys();
   // checkToken();
    loadSteps();
  }, []);

  async function loadSteps(){
    let res = await getAllJourneySteps();
    if (res.success){
      setSteps(res.data);
      setLoaded(true);
    }
  }

  function loadOnboarding(){
    const timer = setTimeout(() => {
      setShowOnboarding(true);
      setShowOnboarding1(true);
    }, 1000);
    return () => clearTimeout(timer);
  }

  async function handleNextStep(step){
    if (step == 1){
      setCurrentStep(step);
      setShowOnboarding1(false);
      setShowOnboarding2(true);
    }

    else if (step == 2){
      let res = await updateUser({preferred_name: preferredName});

      if (res.success){
        setShowOnboarding2(false);
        setShowOnboarding3(true);
      }
    }

    else if (step == 3){
      let res = await updateUser({onboarding_complete: true});

      if (res.success){
        setShowOnboarding2(false);
        setShowOnboarding3(false);
        setShowOnboarding(false);
      }
    }
  }

  useEffect(() => {
    if (loadedOnboarding == true && onboardingStatus == false)
    {
      loadOnboarding();
    }
  }, [loadedOnboarding]);

  async function handleShowChat(id, item){
    setSelectedStepId(id);
    setSelectedStep(item);
    setShowChat(true);
  }

  async function handleShowUnlock(id, item){
    setShowUnlockModal(true);
    setSelectedStepId(id);
    setSelectedStep(item);
  }

  async function handleOutputTypeClick(id, item){
    let res = await getAllJourneyStepsByOutputType(id);

    if (res.success){
      setSteps(res.data);
      setSelectedOutputType(item);
      setSelectedOutputTypeId(id);
    }

    let res2 = await getAllJourneyStepsNotInOutputType(id);

    if (res2.success){
      setOtherSteps(res2.data);
    }
  }

  async function handlePreference(name){
    let element = document.getElementById(`pref_${name}`);
    element.classList.toggle('selected');

    if (element.classList.contains('selected')){
      let res = await addUserPreference(name);
    }

    else{
      let res = await removeUserPreference(name);
    }
  }

  async function handleJourneyClick(id, item){

    setCurrentJourneyId(id);
    setSelectedJourney(item);
  }

  useEffect(() => {
    if (currentJourneyId != null){
      setSteps([]);
      loadSteps();
    }
  }, [currentJourneyId]);

return (
  <div>
        {loadedJourneys == false && currentJourneyId == null && (
          <div>
            <Spinner />
          </div>
        )}

      {outputTypes.length > 0 && (
          <FadeIn delay={300} duration={300}>
            <div className='mb-10 my-6'>
            <div class="text-gray-500 sm:text-lg dark:text-gray-400 flex flex-row items-center justify-between sm:flex-row">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">What would you like to create today?</h2>

                    <p class="mb-8 font-light lg:text-xl"></p>

                  <button onClick={() => navigate('/content')} type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
  <FaIcons.FaRegFolder /> My Content
</button>
                </div>
            </div>

            <div className="space-y-5 mb-4">
              <div className="bg-teal-50 border-t-2 border-teal-500 rounded-lg p-4 dark:bg-teal-800/30" role="alert" tabindex="-1" aria-labelledby="hs-bordered-success-style-label">
                <div className="flex items-center">
                  <div className="shrink-0">
                    <span className="inline-flex justify-center items-center size-8 rounded-full border-4 border-teal-100 bg-teal-200 text-teal-800 dark:border-teal-900 dark:bg-teal-800 dark:text-teal-400">
                      <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                        <path d="m9 12 2 2 4-4"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="ms-3">
                    <div id="hs-bordered-success-style-label" className="text-gray-800 font-semibold dark:text-white">
                      Your content is ready for generation.
                    </div>
                    <p className="text-sm text-gray-700 dark:text-neutral-400">
                      Visit the content studio to review.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-10 bg-yellow-50 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-4 dark:bg-yellow-800/10 dark:border-yellow-900 dark:text-yellow-500" role="alert" tabindex="-1" aria-labelledby="hs-with-description-label">
  <div class="flex items-center">
    <div class="shrink-0">
      <svg class="shrink-0 size-4 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
        <path d="M12 9v4"></path>
        <path d="M12 17h.01"></path>
      </svg>
    </div>
    <div class="ms-4">
      <h3 id="hs-with-description-label" class="text-sm font-semibold">
        Content is being generated.
      </h3>
      <div class="mt-1 text-sm text-yellow-700">
        We are unable to save any progress at this time.
      </div>
    </div>
  </div>
</div>

            {selectedOutputTypeId != null && (
              <div className='flex align-center'>
                <span className='link' onClick={() => {
                  setSelectedOutputType(null);
                  setSelectedOutputTypeId(null)
                }}>&lt; Back</span>
              </div>
            )}

          <ul>
            {outputTypes.map((item, index) => (
              <>
                {(selectedOutputTypeId == null || selectedOutputTypeId == item.output_type_id) && (
                <li 
                key={index}
                style={{
                  margin: '5px',
                  borderBottom: 'solid 1px #ccc',
                  alignItems: 'center'
                }}
                className="cursor-pointer rounded-md flex justify-between gap-x-6 px-5 py-5 bg-white hover:border-b-2 hover:border-gray-400 hover:bg-gray-200 transition-all duration-200"
                onClick={() => handleOutputTypeClick(item.output_type_id, item)}
              >
              
                <div className="flex min-w-0 gap-x-4" style={{alignItems: 'center'}}>
                  <span style={{fontSize: '30px'}}><IconDisplay iconName={item.fa_icon} /></span>

                  <div className="min-w-0 flex-auto">
                    <p className="mb-2 text-1xl font-bold tracking-tight text-gray-900 dark:text-white">{item.name}</p>

                    <p className='mt-1 truncate text-xs/5 text-gray-500'>
                      {item.description}
                    </p>

                    <div className="mt-1 truncate text-xs/5 text-gray-500 font-bold">
                      6 Interviews Available
                    </div>
                  </div>
                </div>
              </li>
                )}
              </>
            ))}
          </ul>
          </FadeIn>
        )}

        {selectedOutputTypeId != null && (
          <div className=' mt-10'>
            <h2 class="mb-4 mt-6 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Recommended Interviews</h2>
          {steps != null && steps.length > 0 && (
            
            <FadeIn delay={200} duration={200} className='dashboardoutputtypes__interviews'>
              {steps.map((item, index) => (
                <div key={index} className='dashboardoutputtypes__interview'>
                  <div>
                    <div className='dashboardoutputtypes__interview-image'>
                      <img src={item.image_url} />
                    </div>
          
                  <div className='p-4'>
                    <div className='text-xs mb-1'>
                      {item.journey_name}
                    </div>

                    <div className='dashboardoutputtypes__interview-title'>
                      <h2>{index + 1}. {item.name}</h2>
            
                      <div className='mt-2 text-sm font-normal'>
                        {item.description}
                      </div>
                    </div>

                    <div className='text-[12px] mt-2'>
                      Taken: 2 times
                    </div>
                  </div>
                </div>

            <div className='dashboard__journey-cta px-4 py-4'>
                  {item.is_completed != true && item.is_free == true && (
                  <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                    <FaIcons.FaMicrophoneAlt /> Start Interview
                  </button>
                  )}

                {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == false && (
                  <button className='cursor-pointer bg-indigo-600 mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowUnlock(item.journey_step_id, item)}>
                    <FaLock /> Unlock
                  </button>
                  )}

                {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == true && (
                  <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                    <FaIcons.FaMicrophoneAlt /> Start Interview
                  </button>
                  )}

                  {item.is_completed == true && item.is_free == true && (
                    <span className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                      <FaIcons.FaMicrophoneAlt /> Start Interview
                    </span>
                  )}

              {item.is_completed == true && item.is_free == false && (
                    <button className='cursor-pointer bg-indigo-600 mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowUnlock(item.journey_step_id, item)}>
                    <FaLock /> Unlock
                  </button>
                  )}

                  <button className='cursor-pointer mt-2 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-white-100 text-indigo-600 hover:bg-gray-100  border-2 border-transparent flex gap-1 items-center justify-center'>
                    View Examples
                  </button>
                </div>
      
              </div>
              ))}
            </FadeIn>
          )}

          <div className='center mt-10'>
          <button onClick={() => setShowOther(true)} type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-800 text-gray-800 hover:border-gray-500 hover:text-gray-500 focus:outline-none focus:border-gray-500 focus:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:border-white dark:text-white dark:hover:text-neutral-300 dark:hover:border-neutral-300">
  Load other interviews
</button>
          </div>
        </div>
        )}

        {showOther == true && (
          <>
          <div className='mt-10'>
          <h2 class="mb-4 mt-6 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Other Interviews</h2>
          </div>

           <FadeIn delay={200} duration={200} className='dashboardoutputtypes__interviews mt-8'>
           {otherSteps.map((item, index) => (
             <div key={index} className='dashboardoutputtypes__interview'>
             <div>
               <div className='dashboardoutputtypes__interview-image'>
                 <img src={item.image_url} />
               </div>
     
             <div className='p-4'>
               <div className='text-xs mb-1'>
                 {item.journey_name}
               </div>

               <div className='dashboardoutputtypes__interview-title'>
                 <h2>{index + 1}. {item.name}</h2>
       
                 <div className='mt-2 text-sm font-normal'>
                   {item.description}
                 </div>
               </div>

               <div className='text-[12px] mt-2'>
                 Taken: 2 times
               </div>
             </div>
           </div>

       <div className='dashboard__journey-cta px-4 py-4'>
             {item.is_completed != true && item.is_free == true && (
             <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
               <FaIcons.FaMicrophoneAlt /> Start Interview
             </button>
             )}

           {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == false && (
             <button className='cursor-pointer bg-indigo-600 mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowUnlock(item.journey_step_id, item)}>
               <FaLock /> Unlock
             </button>
             )}

           {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == true && (
             <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
               <FaIcons.FaMicrophoneAlt /> Start Interview
             </button>
             )}

             {item.is_completed == true && (
               <span className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                 <FaIcons.FaMicrophoneAlt /> Start Interview
               </span>
             )}

             <button className='cursor-pointer mt-2 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-white-100 text-indigo-600 hover:bg-gray-100  border-2 border-transparent flex gap-1 items-center justify-center'>
               View Examples
             </button>
           </div>
 
         </div>
           ))}
         </FadeIn>
         </>
        )}

    {/* {loadedJourneys == true && currentJourneyId == null && (
      <FadeIn delay={300} duration={300}>
        <div className='dashboard__top-header mt-20'>
          <h1>Available Journeys</h1>
        </div>

        <p className='mt-20'>
          Choose a journey to begin crafting content.
        </p>

        <div className='dashboard__journey-items mt-10'>
          {journeys.map((item, index) => (
            <div key={index} className='dashboard__journey-item' style={{
              backgroundImage: `url("${item.image_url}")`,
              backgroundSize: 'cover'
            }} onClick={() => handleJourneyClick(item.journey_id, item)}>
              <div className='dashboard__journey-item-body'>
                <div className='dashboard__journey-item-name'>
                  {index + 1}. {item.name}
                </div>

                <div className='dashboard__journey-item-btn'>
                  <button className='btn'>{item.completed_steps} / {item.step_count} Interviews</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </FadeIn>
    )} */}

    {currentJourneyId != null && (
      <>
      <div className='mt-10'>
        <span className='link' onClick={() => {
          setCurrentJourneyId(null);
          setSelectedJourney(null);
          setSteps([])
        }}><FaCaretLeft /> Back to Journeys</span>
      </div>

        <div className='dashboard__top-header mt-20'>
          <h1>{selectedJourney.name}</h1>
        </div>

        <p className='mt-10'>
          <i>Start your interview journey.</i>
        </p>

        {loaded == false && (
          <div className='center'>
            <FaSpinner />
          </div>
        )}

        <div className='dashboard__journeys mt-10'>
          {steps != null && steps.length > 0 && (
            <>
              {steps.map((item, index) => (
                <div key={index} className='dashboard__journey' style={{
                  backgroundImage: `url("${item.image_url}")`,
                  backgroundSize: 'cover',
                }}>
        
                <div className='dashboard__journey-title'>
                  <h2>{index + 1}. {item.name}</h2>
        
                  <div className='dashboard__journey-description'>
                    {item.description}
                  </div>

                  {/* <div className='dashboard__journey-description'>
                    Interview length: {item.min_duration}-{item.max_duration} mins
                  </div> */}
                </div>
        
                <div className='dashboard__journey-cta'>
                  {item.is_completed != true && item.is_free == true && (
                  <button className='btn dashboard__journey-btn' onClick={() => handleShowChat(item.journey_step_id, item)}>
                    Get Started
                  </button>
                  )}

                {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == false && (
                  <button className='btn dashboard__journey-btn' onClick={() => handleShowUnlock(item.journey_step_id, item)}>
                    <FaLock /> Unlock
                  </button>
                  )}

                {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == true && (
                  <button className='btn dashboard__journey-btn' onClick={() => handleShowChat(item.journey_step_id, item)}>
                    Get Started
                  </button>
                  )}

                  {item.is_completed == true && (
                    <span className='dashboard__journey-completed-flag' onClick={() => handleShowChat(item.journey_step_id, item)}>
                      Completed
                    </span>
                  )}
                </div>
              </div>
              ))}
            </>
          )}
        </div>
        </>
    )}

  <div>
    {showChat && (
      <FadeIn delay={300} duration={300}>
      <div>
        <FirstAssistant showChat={showChat} setShowChat={setShowChat} journey_step_id={selectedStepId} selectedStep={selectedStep} loadSteps={loadSteps} />
      </div>
      </FadeIn>
    )}
    
    {showOnboarding && (
      <FadeIn delay={300} duration={300}>
        <div className='overlay'></div>
        <div className='dashboard__onboarding'>
          <div style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: '50000'
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" height="40">
  <polyline 
    points="
      40,50 
      50,30 60,70 
      70,20 80,80 
      90,35 100,65 
      110,45 120,55 
      130,48 140,52 
      150,49 160,51"
    fill="none"
    stroke="#2563eb "
    strokeWidth="6"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  
  <circle cx="170" cy="50" r="3" fill="#FF3366"/>
</svg>
          </div>

          <div>
            <h2></h2>
          </div>

          {showOnboarding1 && (
            <>
          <h2>
            <div>
              <TypeWriter text="Hello! Let's setup your new interviewer!" speed={60} />
            </div>
          </h2>


          <p className='mt-30'>
            <button className='btn' onClick={() => handleNextStep(1)}>Next</button>
          </p>
        </>
          )}

{showOnboarding2 && (
      <FadeIn delay={300} duration={300}>
        <div className='overlay'></div>
        <div className='dashboard__onboarding'>
        <h2>
            <div>
              <TypeWriter text="What would you like your interviewer to call you?" speed={60} />
            </div>
          </h2>

          <p style={{
            width: '50%',
            margin: '20px auto'
          }} className='mt-30'>
            <input type='text' style={{
              width: '400px'
            }} className='input form-control' placeholder='Name' value={preferredName} onChange={(e) => setPreferredName(e.target.value)} />
          </p>

          <p >
            <button className='btn' onClick={() => handleNextStep(2)}>Next</button>
          </p>
        </div>
      </FadeIn>
    )}

{showOnboarding3 && (
      <FadeIn delay={300} duration={300}>
        <div className='overlay'></div>
        <div className='dashboard__onboarding'>
        <h2>
            <div>
              <TypeWriter text="Select the tone that you would like your interviewer to have" speed={60} />
            </div>
          </h2>

          <div className='dashboard__onboarding-tone-items'>
            <div id='pref_Friendly' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Friendly')}>
              Friendly
            </div>

            <div id='pref_Personal' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Personal')}>
              Personal
            </div>

            <div id='pref_Political' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Political')}>
              Political
            </div>

            <div id='pref_Edgy' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Edgy')}>
              Edgy
            </div>

            <div id='pref_Controversial' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Controversial')}>
              Controversial
            </div>
          </div>

          <p>
            <button className='btn' onClick={() => handleNextStep(3)}>Next</button>
          </p>
        </div>
      </FadeIn>
    )}
        </div>
      </FadeIn>
    )}


  </div>

  {showUnlockModal && (
    <>
      <div className='overlay'></div>

      <div className='modal dashboard__modal-unlock'>
        <div>
          <img src={selectedStep.image_url} alt='' />
        </div>

        <h2 className='modal__h2 center'>
          Upgrade your account to continue your journey
        </h2>

        <div className='mt-10 center btns'>
          <Link to='/upgrade' className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
            GO PRO
          </Link>

          <div className='mt-30'>
            <span className='link link--skip link--cancel' onClick={()=> setShowUnlockModal(false)}>No Thanks</span>
          </div>
        </div>
      </div>
    </>
  )}
  </div>
)}

export default DashboardOutputTypes;