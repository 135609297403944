import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { refresh } from '../../../services/auth.service';
import Spinner from '../../../components/layout/Spinner';
import FadeIn from '../../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getBlogHome, getBlogCategories } from '../../../utils/api';
import './NewsPage.css';
import '../../contenthub/blog/BlogHome.css';

function NewsPage() {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [numberToShow, setNumberToShow] = useState(20);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData(){
      let res = await getBlogHome();

      if (res.success){
        setData(res.data.edges);
        setLoaded(true);
      }
    }

    async function loadCategories(){
      let res = await getBlogCategories();

      if (res.success){
        setCategories(res.data.edges);
      }
    }

    loadData();
    loadCategories();
  }, []);

  async function loadMore(){
    setNumberToShow(current => current + 9);
  }

  function removeHTMLTags(text) {
    // Create a temporary DOM element
    let tempElement = document.createElement('div');
    
    // Set the innerHTML of the temporary element to the provided text
    tempElement.innerHTML = text;
    
    // Retrieve the text content of the temporary element, which excludes HTML tags
    return tempElement.textContent || tempElement.innerText || '';
  }

  return (
    <div className='newspage'>
      <Helmet>
        <title>YourInterviewer Blog</title>
        <link rel="canonical" href={`https://www.yourinterviewer.com/blog`} />
      </Helmet>

      <div className='newspage__hero'>
        <h1 className='newspage__hero-h1'>
          Latest News
        </h1>

        {/* <div className='homepagehub__hero-subheader'>
          Browse our community of software solutions and discover new and innovative ways to push the boundaries of your business. Also gain unlimited exclusive access to our library of educational segments from top industry leaders and experts.
        </div> */}
      </div>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
        <>
        <div className='bloghome__latest'>
        {data.slice(0, 1).map((item, index) => (
        <>
          <div className='bloghome__latest-thumbnail'>
            <Link to={`/blog/${item.node.slug}`}>
            <img src={item.node.featuredImage?.node.sourceUrl} alt="Image of post" />
            </Link>
          </div>

          <div className='bloghome__latest-copy'>
            <div className='bloghome__latest-title'>
              <Link to={`/blog/${item.node.slug}`}>
              {item.node.title}
              </Link>
            </div>

            <div className='bloghome__latest-description'>
              {removeHTMLTags(item.node.excerpt)}
            </div>

            <div className='bloghome__latest-date'>
              Published on: {new Date(item.node.date).toLocaleDateString()}
            </div>
          </div>
          </>
        ))}
        </div>

         <div className='newspage__list'>
            {data.slice(1, numberToShow).map((item, index) => (
              <div key={index} className='newspage__list-item'>
                  <div className='newspage__list-item-thumbnail'>
                    <Link to={`/blog/${item.node.slug}`}>

                    <img src={item.node.featuredImage?.node.sourceUrl} alt="Image of post" />
                    </Link>
                  </div>

                  <div className='newspage__list-item-title-wrapper'>
                    <div className='newspage__list-item-category'>
                      STARTUPS
                    </div>

                  <div className='newspage__list-item-title'>
                    <Link to={`/blog/${item.node.slug}`}>
                    {item.node.title}
                    </Link>
                  </div>

                  <div className='bloghome__list-item-date'>
                    Published on: {new Date(item.node.date).toLocaleDateString()}
                  </div>
                  </div>
              </div>
            ))}
       </div>

       <div className='center mt-20'>
            <button className='btn' onClick={() => loadMore()}>Load more</button>
      </div>
      </>
      )}
    </div>
  );
}

export default NewsPage;