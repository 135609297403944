import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getJourneyInterviews, getJourneys, getJourneyStep, updateJourneyStep, addJourneyStep } from '../../utils/api/admin';

function JourneyStepAdd({journey_step_id, setShowModal, loadData}) {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [name, setName] = useState('');
  const [journeys, setJourneys] = useState([]);
  const [selectedJourneyId, setSelectedJourneyId] = useState(null);
  const [description, setDescription] = useState('');
  const [assistantId, setAssistantId] = useState('');
  const [minDuration, setMinDuration] = useState('0');
  const [maxDuration, setMaxDuration] = useState('0');
  const [imageUrl, setImageUrl] = useState('');
  const [orderId, setOrderId] = useState('');
  const [outputPrompt, setOutputPrompt] = useState('');
  const [structuredDataSchema, setStructuredDataSchema] = useState('');
  const [status, setStatus] = useState('Draft');
  const [isFree, setIsFree] = useState(false);
  const [seoUrl, setSeoUrl] = useState('');
 // const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadJourneys(){
      let res = await getJourneys();

      if (res.success){
        setJourneys(res.data);
      }
    }

    loadJourneys();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleUpdate(){
    let res = await addJourneyStep(selectedJourneyId, name, description, imageUrl, assistantId, minDuration, maxDuration, orderId, outputPrompt, structuredDataSchema, status, isFree, seoUrl);

    if (res.success){
      alert('Saved');
      loadData();
      setShowModal(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Journeys</title>
      </Helmet>

         <div>
          <div>
            <div>
              <select className='input' value={selectedJourneyId} onChange={(e) => setSelectedJourneyId(e.target.value)}>
                <option>Select Journey</option>

                {journeys.map(item => (
                  <option value={item.journey_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group'>
              <label>Name</label>
              <input type='text' className='input' value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className='form-group'>
              <label>Description</label>
              <textarea type='text' className='input' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>

            <div className='form-group'>
              <label>SEO URL {'(/interviews/name-goes-here)'}</label>
              <input type='text' className='input' value={seoUrl} onChange={(e) => setSeoUrl(e.target.value)} />
            </div>

            <div className='form-group'>
              <label>Order ID</label>
              <input type='text' className='input' value={orderId} onChange={(e)=> setOrderId(e.target.value)} />
            </div>

            <div className='form-group'>
              <label>Assistant ID (From VAPI)</label>
              <input type='text' className='input' value={assistantId} onChange={(e) => setAssistantId(e.target.value)} />
            </div>

            <div className='form-group'>
              <label>Min Duration (in minutes)</label>
              <input type='text' className='input' value={minDuration} onChange={(e) => setMinDuration(e.target.value)} />
            </div>

            <div className='form-group'>
              <label>Max duration (in minutes)</label>
              <input type='text' className='input' value={maxDuration} onChange={(e) => setMaxDuration(e.target.value)} />
            </div>

            <div className='form-group'>
              <div>
                <img style={{width: '100%'}} src={`${imageUrl}`} />
              </div>
            </div>

            <div className='form-group'>
              <label>Image URL</label>
              <input type='text' className='input' value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
            </div>

            <div className='form-group'>
              <label>Output Prompt</label>
              <textarea type='text' className='input' value={outputPrompt} onChange={(e) => setOutputPrompt(e.target.value)}></textarea>
            </div>

            <div className='form-group'>
              <label>Structured Data Schema</label>
              <textarea type='text' className='input' value={structuredDataSchema} onChange={(e) => setStructuredDataSchema(e.target.value)}></textarea>
            </div>
     
            <div className='form-group'>
              <label>Status</label>
              <select className='input select' value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value='Draft'>Draft</option>
                <option value='Published'>Published</option>
                <option value='Paused'>Paused</option>
              </select>
            </div>

            <div className='form-group'>
              <label>Is Free</label>
              <input type='checkbox' checked={isFree} onChange={(e) => setIsFree(e.target.checked)} />
            </div>

            <div className='form-group'>
              <button className='btn' onClick={() => handleUpdate()}>Save</button>
            </div>
          </div>
       </div>

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default JourneyStepAdd;