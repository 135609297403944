import {useState,useEffect} from 'react';
import React from "react";
import {createRoot} from 'react-dom/client'
import './ContentStudioItem.css';
import { useLocation } from 'react-router-dom';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, getPreferredName, getCompletedInterviews, getCallReport, getContentStudioItems, getCompleteInterview, updateLikeStatus, generateContent, deleteContent, testPrompt, generateAnthropicContent, getInterviewModifiers, addInterviewModifier, removeInterviewModifier, getOutputTypesForInterview, getOutputTypeById } from '../../utils/api';
import { FaBriefcase, FaCalendarAlt, FaCaretDown, FaCaretLeft, FaCheckCircle, FaCloud, FaCopy, FaHeart, FaLinkedin, FaLock, FaMagic, FaRegCalendarCheck, FaRegCopy, FaRegEdit, FaRegHeart, FaRegLightbulb, FaRegThumbsDown, FaRegThumbsUp, FaSoundcloud, FaTimes, FaTrashAlt, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import TypeWriter from './components/TypeWriter';
import OutputTypeRenderer from './components/OutputTypeRenderer';
import Markdown from 'react-markdown';

const ContentStudioItem = () => {
  const [interviews, setInterviews] = useState([]);
  const [interview, setInterview] = useState(null);
  const [items, setItems] = useState([]);
  const [journeyStep, setJourneyStep] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loadedItems, setLoadedItems] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [status, setStatus] = useState('');
  const [modifyPrompt, setModifyPrompt] = useState('');
  const [hasContent, setHasContent] = useState(false);
  const [modifiers, setModifiers] = useState([]);
  const [outputTypes, setOutputTypes] = useState([]);
  const [loadedOutputTypes, setLoadedOutputTypes] = useState(false);
  const [isModifying,setIsModifying] = useState(false);
  const [selectedOutputType, setSelectedOutputType] = useState(null);
  const [isRendered, setIsRendered] = useState(false);
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  let {id, outputid} = useParams();
  const navigate = useNavigate();

  async function loadItems(){
    let res = await getContentStudioItems(id, outputid);

    if (res.success){
      setItems(res.data);
      setLoadedItems(true);

      if (res.data.length > 0){
        setHasContent(true);
      }
    }
  }

  async function loadItemsByType(output_type_id){
    let res = await getContentStudioItems(id, output_type_id);

    if (res.success){
      setItems(res.data);
      setLoadedItems(true);

      if (res.data.length > 0){
        setHasContent(true);
      }
    }
  }

  async function loadModifiers(){
    let res = await getInterviewModifiers(id);

    if (res.success){
      setModifiers(res.data);
    }
  }

  useEffect(() => {
    async function loadOutputTypes(){
      let res = await getOutputTypesForInterview();

      if (res.success){
        setOutputTypes(res.data);
      }
    }

    async function loadOutputType(){
      let res = await getOutputTypeById(outputid);

      if (res.success){
      //  setOutputTypes(res.data);
        setLoadedOutputTypes(true);
        setSelectedOutputType(res.data);
        console.log('output types', res.data);
      }
    }

    async function loadModifiers(){
      let res = await getInterviewModifiers(id);

      if (res.success){
        setModifiers(res.data);
      }
    }

    async function loadInterview(){
      let res = await getCompleteInterview(id);

      if (res.success){
        setInterview(res.data);
        if (res.data.content_generated == true)
          setShowBtn(false);
        else
          setShowBtn(true);
        setLoaded(true);
      }
    }

    async function loadItems(){
      let res = await getContentStudioItems(id, outputid);

      if (res.success){
        setItems(res.data);
        setLoadedItems(true);

        if (res.data.length > 0){
          setHasContent(true);
        }
      }
    }

    async function loadCallReport(){
      let res = await getCallReport(id);

      if (res.success){
        setStatus(res.data);
      }
    }

    loadInterview();
    loadCallReport();
    loadItems();
    loadModifiers();
    loadOutputTypes();
    //setSelectedOutputType(outputid);
    loadOutputType();
  }, []);

  async function handleGenerate(){
    setIsGenerating(true);

    if (modifyPrompt.length > 0){
      await handleAddModifier();
      setModifyPrompt('');
    }

    let res = await generateAnthropicContent(id, modifyPrompt, selectedOutputType.output_type_id);

    if (res.success){

      setTimeout(function(){
        loadItemsByType(selectedOutputType.output_type_id);
        setIsGenerating(false);
        loadModifiers();
      }, 3000)

      setShowBtn(false);
    }
  }

  async function handleCopy(text, id){
    navigator.clipboard.writeText(text)
        .then(() => {
          document.getElementById('btncopy_' + id).innerHTML = 'Copied!';
          setTimeout(function(e) {
            document.getElementById('btncopy_' + id).innerHTML = `Copy <i class='fa fa-copy'></i>`;
          }, [1000])
           // alert("Text copied to clipboard!");
        })
        .catch(err => {
         //   alert("Failed to copy text.");
        });
  }

  async function handleLike(id){
    let res = await updateLikeStatus(id, true);
    loadItems();
  }

  async function handleUnlike(id){
    let res = await updateLikeStatus(id, false);
    loadItems();
  }

  async function handleDelete(id){
    let res = await deleteContent(id);

    if (res.success){
      loadItems();
    }
  }

  async function handleTestPrompt(){
    let res = await testPrompt();
    let posts = JSON.parse(res.data);
    console.log('res', res);
    console.log('posts', posts);
  }

  function RenderWithLineBreaks({ text }) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text.replace(/\n/g, '<br />'),
        }}
      />
    );
  }

  async function handleAddModifier(){
    let res = await addInterviewModifier(id, modifyPrompt);

    if (res.success){
        loadModifiers();
    }
  }

  async function handleRemoveModifier(id){
    let res = await removeInterviewModifier(id);

    if (res.success){
      loadModifiers();
    }
  }

  async function handleChangeType(val){
    setSelectedOutputType(val);
    setShowTypeDropdown(false);

    await loadItemsByType(val.output_type_id);
  }

  return (
    <div className="contentstudioitem">
      <div className="mb-20">
        <span className="link" onClick={() => navigate(-1)}><FaCaretLeft/> Back</span>
      </div>

      <div>
        {loaded == true && (
          <div>
            <div>
              {loadedOutputTypes == true && (
                <>
                  <span onClick={() => setShowTypeDropdown(!showTypeDropdown)} className='contentstudioitem__output-type-label'>{selectedOutputType.name} <FaCaretDown /></span>

                  {showTypeDropdown && (
                    <FadeIn delay={100} duration={100}>
                      <div className='contentstudioitem__output-type-dropdown'>
                      {outputTypes.map(item => (
                        <div onClick={() => handleChangeType(item)}>
                          {item.name}
                        </div>
                      ))}
                      </div>
                    </FadeIn>
                  )}
                </>
              )}
            </div>
              <div className="contentstudioitem__step-image">
                <img src={interview.image_url} />
              </div>

              <div className='contentstudioitem__step-name'>
                {interview.name}
              </div>

              <div className='contentstudioitem__step-description'>
                {interview.description}
              </div>

              <div className="contentstudioitem__step-description">
                <strong><FaCheckCircle /> Your interview date:</strong> {new Date(interview.date_created).toLocaleDateString()}
              </div>
          </div>
        )}

        {loaded == true && isGenerating == false && status == 'ended' && (
          <div className="center contentstudioitem__btn-generate-wrapper">
            {hasContent == true && (
            <input className='input' placeholder="How would you like to modify this content? Leave blank to regenerate content without modifications." value={modifyPrompt} onChange={(e) => setModifyPrompt(e.target.value)} />
            )}

            <button className='btn contentstudioitem__btn-generate' onClick={() => handleGenerate()}><FaMagic style={{fontSize: '22px'}} /> <span className='ml-10'>Generate Content</span></button>
          </div>
        )}

        {loaded == true && isGenerating == false && showBtn == true && status != 'ended' && (
          <div className="center" style={{marginTop: '50px'}}>
            <i>CONTENT IS STILL BEING GENERATED. PLEASE WAIT 1-2 MINUTES.</i>
          </div>
        )}

        {loaded == true && isGenerating == true && (
          <div className="center contentstudioitem__btn-generate-wrapper">
            <Spinner /> <span className='ml-10'><i>Generating Content...</i></span>
          </div>
        )}

        <div>
          {loadedItems == false && (
            <div className="center">
              <Spinner />
            </div>
          )}

          {loadedItems == true && items.length == 0 && (
            <div className='mt-30 center'>
              <i>No content genereated yet...</i>
            </div>
          )}

          {loadedItems == true && items.length > 0 && (
            <>
            {modifiers.length > 0 && (
              <div className="mt-20"><strong><FaMagic /> Adjustments</strong></div>
            )}

            <div className='contentstudioitem__modifiers'>
            {modifiers.map(m => (
              <div className="contentstudioitem__modifier">
                <span>{m.modifier_text}</span>
                <span className='ml-10' onClick={() => handleRemoveModifier(m.journey_step_interview_modifier_id)}><FaTimes /></span>
              </div>
            ))}
            </div>

            {items.length > 0 && (
              <div className="mt-20">
                <strong>{items.length} content pieces</strong>
              </div>
            )}

            {selectedOutputType == null && loaded && items.length > 0 && (
              <Spinner />
            )}

            {selectedOutputType != null && loaded && items.length > 0 && (
              <div className='contentstudioitem__items'>
                {items.map (item => (
                  <FadeIn className='contentstudioitem__item' delay={300} duration={300}>
                  <div>
                    {item.content_structured_data == null && item.markdown_content == null && (
                      <>
                      <OutputTypeRenderer isModifying={false} template={selectedOutputType.output_template} aiData={JSON.stringify(item)} template_style={selectedOutputType.template_style}/>
                      </>
                    )}

                    {item.content_structured_data != null && item.markdown_content == null && (
                    <>
                      <OutputTypeRenderer isModifying={false} template={selectedOutputType.output_template} aiData={item.content_structured_data} template_style={selectedOutputType.template_style}/>
                      </>
                    )}

                    {item.markdown_content != null && (
                      <Markdown>{item.markdown_content}</Markdown>
                    )}

                  <div className="contentstudioite__item-date">
                    <strong>Generated on:</strong> {new Date(item.date_created).toLocaleString()}
                  </div>
         
                    <div className='contentstudioitem__item-menu'>
                    <div className='contentstudioitem__item-btns'>
                      {item.is_liked == false && (
                      <div onClick={() => handleLike(item.journey_interview_content_id)}><FaRegHeart /></div>
                      )}

                      {item.is_liked == true && (
                        <div onClick={() => handleUnlike(item.journey_interview_content_id)}><FaHeart style={{color: 'salmon', fill: 'salmon'}} /></div>
                      )}

                        <div onClick={() => handleDelete(item.journey_interview_content_id)}>
                        <FaTrashAlt />
                        </div>
                    </div>

                    <div className="contentstudioitem__item-preview">
                      
                      <button id={`btncopy_${item.journey_interview_content_id}`} className='ml-10 btn' onClick={() => handleCopy(item.markdown_content, item.journey_interview_content_id)}>Copy</button>
                    </div>
                  </div>
                  </div>
                  </FadeIn>
                ))}
              </div>
            )}

            {/* {selectedOutputType != null && loaded && items.length > 0 && (
            <div className='mt-10'>
                  <OutputTypeRenderer isModifying={false} template={selectedOutputType.output_template} aiData={JSON.stringify(items)} template_style={selectedOutputType.template_style} />
                </div>
            )} */}

            {/* <div className='contentstudioitem__items'>
              {items.map(item => (
                <div className="contentstudioitem__item">
                  <div className="contentstudioitem__item-copy">
                    {item.content_body}

                  </div>

                  <div className="contentstudioite__item-date">
                    <strong>Generated on:</strong> {new Date(item.date_created).toLocaleString()}
                  </div>

                  <div className='contentstudioitem__item-menu'>
                    <div className='contentstudioitem__item-btns'>
                      {item.is_liked == false && (
                      <div onClick={() => handleLike(item.journey_interview_content_id)}><FaRegHeart /></div>
                      )}

                      {item.is_liked == true && (
                        <div onClick={() => handleUnlike(item.journey_interview_content_id)}><FaHeart style={{color: 'salmon', fill: 'salmon'}} /></div>
                      )}

                        <div onClick={() => handleDelete(item.journey_interview_content_id)}>
                        <FaTrashAlt />
                        </div>
                    </div>

                    <div className="contentstudioitem__item-preview">
                      
                      <button id={`btncopy_${item.journey_interview_content_id}`} className='ml-10 btn' onClick={() => handleCopy(item.content_body, item.journey_interview_content_id)}>Copy</button>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            </>
          )}
        </div>
      </div>

      <div>
        <>
        {interviews.map((item, index) => (
            <div className='dashboard__journey' style={{
              backgroundImage: `url("${item.image_url}")`,
              backgroundSize: 'cover'
            }}>
    
            <div className='dashboard__journey-title'>
              <h2>{index + 1}. {item.name}</h2>
    
              <div className='dashboard__journey-description'>
                 {item.description}
              </div>

              <div className='dashboard__journey-description'>
                Interview length: {item.min_duration}-{item.max_duration} mins
              </div>
            </div>
          </div>
          ))}
        </>
      </div>
    </div>
  );
};

export default ContentStudioItem;
