import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaPlay, FaTimes } from 'react-icons/fa';
import { getJourneyInterviews, getJourneys, getJourneyStep, updateJourneyStep, addJourneyStep, addOutputType, updateOutputType, getOutputType, anthropicTest } from '../../utils/api/admin';
import OutputTypeRenderer from './Components/OutputTypeRenderer';
import Typewriter from './Components/TypeWriter';
import * as FaIcons from 'react-icons/fa';
import Markdown from 'react-markdown';

function OutputTypeEdit({output_type_id, setShowModal, loadData}) {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [name, setName] = useState('');
  const [journeys, setJourneys] = useState([]);
  const [selectedJourneyId, setSelectedJourneyId] = useState(null);
  const [description, setDescription] = useState('');
  const [assistantId, setAssistantId] = useState('');
  const [minDuration, setMinDuration] = useState('0');
  const [maxDuration, setMaxDuration] = useState('0');
  const [icon, setIcon] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [orderId, setOrderId] = useState('');
  const [outputPrompt, setOutputPrompt] = useState('');
  const [structuredDataSchema, setStructuredDataSchema] = useState('');
  const [outputTemplate, setOutputTemplate] = useState('');
  const [testData, setTestData] = useState('');
  const [outputStyle, setOutputStyle] = useState('');
  const [status, setStatus] = useState('Draft');
  const [transcript, setTranscript] = useState(`AI: Hi there. I'm Sam, your AI interviewer for today. What's your name?
User: I'm Daniel.
AI: Awesome. Pleasure to meet you, Daniel. Are you ready to get started?
User: Yes.
AI: Could you start by sharing a bit about what you're building,
User: I am beauty. A new AI tool that will help marketers to generate content, personalized content for their ABM campaigns for each target account.
AI: Thank you for sharing that, Daniel. I'd love to understand what inspired you to create this AI powered marketing tool. What drove you to pursue this particular solution?
User: I've managed the ABM campaigns in the past, and I know how hard is it to Create content that resonates with target accounts and move buyers in their buying journey.
AI: Your motivation from firsthand experience is really interesting Before we dive deeper, could you tell me about your background prior to starting this venture? What experiences shaped your path towards developing an AI solution for personalized marketing content?
User: I've managed marketing teams of throughout my career and, um, I also worked as a product marketer for many companies. Uh, including kind some Canadian unicorns, and more recently, I considered for companies that wanted to create more engaging and high performing ABM campaigns.
AI: Taking the leap into entrepreneurship with your own AI venture is such a significant decision. What gave you the confidence or push to make that transition from being an employee to starting your own company?
User: I always believed that I could do more than just being an employee. And I think as entrepreneur, I have The freedom and the power.
AI: Thank you
User: To create something new and impactful.
AI: Thank you for being so open, Daniel. Was there perhaps a specific moment or experience in your life that really crystallized this Vision for an AI powered marketing content tool?
User: Say that again?
AI: Sure. Let me rephrase. Was there a particular instance or experience that solidified your idea for creating an AI solution to personalize marketing content at scale? A moment when you realize the need and potential impact?
User: Yes. Um, when working with a customer, I realized that when we created very targeted and personalized content for an specific account, uh, the deal moved faster and we got better results out out of it.
AI: I see. So that direct experience of seeing the power of personalized content accelerate deals was the catalyst. Building a startup comes with its unique challenges, What helps you stay focused and motivated when things get difficult on this entrepreneurial journey?
User: Right. Keep in mind my vision and my passion, So I believe that this is what moves me and will create the results as I expect.
AI: I can sense your dedication to this work. Looking at the bigger picture, what would you say is your personal why? The deeper mission that drives you forward in solving this problem of personalized marketing content at scale
User: The b to b mark thing traditionally is very boring and low personalized. It's it it's far behind um, towards b to c marketing does. So I want to make b to b marketing become more similar to what b to c delivers.
AI: Thank you for sharing your experience and motivation so openly, Daniel. Your passion for elevating b 2 b marketing to be more personalized and engaging like b 2 c really comes through. I appreciate you taking the time to provide such thoughtful responses. Please feel free to visit the content studio to see what kind of posts we can generate based on our discussion.
`);
  const [isModifying, setIsModifying] = useState(false);
  const [generatedOutput, setGeneratedOutput] = useState('');
  const [fontAwesomeIcon, setFontAwesomeIcon] = useState('');
 // const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0){
      return <p>No icon.</p>
    }
  
    // Dynamically get the icon component from the FaIcons object
    const IconComponent = FaIcons[iconName];
  
    // If the icon is found, render it
    if (IconComponent) {
      return <IconComponent />;
    }
  
    // Fallback if icon is not found
    return <p>Icon not found</p>;
  };

  useEffect(() => {
    async function loadData(){
      let res = await getOutputType(output_type_id);

      if (res.success){
        let sample_data = res.data.sample_data;
        setData(res.data);
        setName(res.data.name);
        setDescription(res.data.description);
        setIcon(res.data.icon_url);
        setOutputPrompt(res.data.output_prompt);
        setStructuredDataSchema(res.data.structured_data_schema);
        setOutputTemplate(res.data.output_template);
        setOutputStyle(res.data.template_style);
        setStatus(res.data.status);
        setFontAwesomeIcon(res.data.fa_icon);

        if (sample_data)
          setTestData(sample_data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleUpdate(){
    let res = await updateOutputType(output_type_id, name, description, fontAwesomeIcon, outputPrompt, structuredDataSchema, outputTemplate, outputStyle, testData, status);

    if (res.success){
      alert('Saved');
      loadData();
      //setShowModal(false);
    }
  }

  async function handleRefresh(){
    setIsModifying(false);
  }

  async function handleTemplateChange(val){
    setIsModifying(true);
    setOutputTemplate(val);
  }

  async function handleAnthropicTest(){
    setSubmitting(true);
    let res = await anthropicTest(transcript, outputPrompt, structuredDataSchema);

    if (res.success){
      setGeneratedOutput(res.data);
      setSubmitting(false);
    }

    else{
      setSubmitting(false);
      setGeneratedOutput(res.message);
    }
  }

  async function handleIconChange(val){
    setFontAwesomeIcon(val);
  }

  function renderContent(data) {
    if (typeof data === 'string' || typeof data === 'number') {
      // Render strings or numbers directly
      return <span>{data}</span>;
    } else if (Array.isArray(data)) {
      // Handle arrays by mapping over their contents
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{renderContent(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof data === 'object' && data !== null) {
      // Handle objects by rendering keys and values
      return (
        <div style={{ marginLeft: '10px', borderLeft: 'solid 1px #ddd', paddingLeft: '10px' }}>
          {Object.entries(data).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {renderContent(value)}
            </div>
          ))}
        </div>
      );
    }
  
    // Handle other data types (e.g., null, undefined)
    return <span>{String(data)}</span>;
  }

  return (
    <>
      <Helmet>
        <title>Journeys</title>
      </Helmet>

         <div>
          <div>
            <div className='form-group'>
              <label>Name</label>
              <input type='text' className='input' value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className='outputtype__editor-wrapper'>
              <div>
              <div className='form-group'>
                  <label>Output Prompt</label>
                  <textarea type='text' className='input' value={outputPrompt} onChange={(e) => setOutputPrompt(e.target.value)}></textarea>
                </div>

                <div className='form-group'>
                  <label>Sample Transcript</label>
                  <textarea type='text' className='input' value={transcript} onChange={(e) => setTranscript(e.target.value)}></textarea>
                </div>

                <div className='form-group'>
                  <label>Structured Data Schema</label>
                  <textarea type='text' className='input' value={structuredDataSchema} onChange={(e) => setStructuredDataSchema(e.target.value)}></textarea>
                </div>

                <div>
                  {submitting == false && (
                    <span className='btn' onClick={() => handleAnthropicTest()}>
                      Run <FaPlay />
                    </span>
                  )}

                  {submitting == true && (
                    <Spinner />
                  )}
                </div>
              </div>

              <div className='outputtype__editor-preview'>
                <div><strong>Response</strong>
                  {generatedOutput.length > 0 && (
                    <span className='ml-10'>{generatedOutput.split(' ').length} words</span>
                  )}
                </div>

                <div className='mt-10'>
                  <div className='contentstudioitem'>
                    <Markdown>{generatedOutput}</Markdown>
                  </div>
                </div>
              </div>
            </div>

            <div className='outputtype__editor-wrapper mt-20'>
              <div>
                <div className='form-group'>
                  <label>Output Template</label>
                  <textarea type='text' className='input' onChange={(e) => handleTemplateChange(e.target.value)} value={outputTemplate}></textarea>
                </div>

                <div className='form-group'>
                  <label>Test Data</label>
                  <textarea type='text' className='input' onChange={(e) => {
                    setIsModifying(true);
                    setTestData(e.target.value);
                  }} value={testData}></textarea>
                </div>

                <div className='form-group'>
                  <label>Output Style</label>
                  <textarea type='text' className='input' value={outputStyle} onChange={(e) => setOutputStyle(e.target.value)}></textarea>
                </div>

                <div className='mt-10'>
                  <button className='btn' onClick={() => handleRefresh()}>Refresh preview</button>
                </div>
              </div>

              <div className='outputtype__editor-preview'>
                <div><strong>Preview</strong></div>

                <div className='mt-10'>
                  <OutputTypeRenderer isModifying={isModifying} template={outputTemplate} aiData={testData} template_style={outputStyle} />
                </div>
              </div>
            </div>

            <div className='form-group'>
              <label>Description</label>
              <textarea type='text' className='input' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>

            <div className='form-group'>
              <label>FA Icon <IconDisplay iconName={fontAwesomeIcon}></IconDisplay></label>
              <input type='text' className='input' placeholder='Icon name' value={fontAwesomeIcon} onChange={(e) => handleIconChange(e.target.value)} />
            </div>

            <div className='form-group'>
              <div>
                <img style={{width: '100%'}} src={`${icon}`} />
              </div>
            </div>

            <div className='form-group'>
              <label>Image URL</label>
              <input type='text' className='input' value={icon} onChange={(e) => setIcon(e.target.value)} />
            </div>
     
            <div className='form-group'>
              <label>Status</label>
              <select className='input select' value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value='Draft'>Draft</option>
                <option value='Published'>Published</option>
                <option value='Paused'>Paused</option>
              </select>
            </div>

            <div className='form-group'>
              <button className='btn' onClick={() => handleUpdate()}>Save</button>
            </div>
          </div>
       </div>

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default OutputTypeEdit;