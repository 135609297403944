import './Login.css';
import { useState, useEffect } from "react";
import { loginUser } from '../../utils/api';
import classNames from "classnames";
import { useNavigate, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, isSeller, isBuyer, isEventHost, isEventAttendee } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import { FaLinkedin } from "react-icons/fa";
import FadeIn from '../../utils/fadein';
import ScrollToTop from '../../components/layout/ScrollToTop';

function Login() {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthed()){
            navigate('/journeys');
        }

        document.title = "Login";
    }, []);

    async function handleSubmit(e){
        e.preventDefault();
        let el1 = e.target.elements.email.value;
        let el2 = e.target.elements.password.value;
        setSubmitting(true);

        if (el1.length > 0 && el2.length > 0){
            let response = await loginUser(el1, el2);

            if (response.success){
               let tokens = response.data;

               localStorage.setItem('access_token', tokens.accessToken);
               localStorage.setItem('refresh_token', tokens.refreshToken);

               setCookie('access_token', tokens.accessToken, 1);;
                window.location = '/journeys';
            }

            else{
                setHasError(true);
                setErrorMessage("Oops! Incorrect email or password");
                setSubmitting(false);
            }
        }
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + d.toUTCString();
        let _domain = `domain=${process.env.REACT_APP_SITE_DOMAIN}`;

        if (process.env.REACT_APP_NODE_ENV === 'Dev'){
            _domain = `domain=${process.env.REACT_APP_API_BASE_URL}`;
        }

        document.cookie = cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
      }

    return (
        <FadeIn delay={300} duration={300}>
            <ScrollToTop />
        <div className="center card__wrapper">
        <Helmet>
          <title>Login</title>
          <meta name="description" content="" />
        </Helmet>

        <div className="card login">
            <div className="">
            <h1 className="card__header login__left-header">Login</h1>
                <form onSubmit={handleSubmit}>
                    <div className='form__group'>
                        <label className='label input__label' htmlFor='txtEmail'>Email</label>

                    <input className={classNames("input", {
                        "input--error":hasError
                    })} type='email' id='txtEmail' required placeholder='Enter your email' name="email" />
                    </div>

                    <div className='form__group'>
                        <label className='label input__label' htmlFor='txtPassword'>Password</label>

                        <input className={classNames("input_box", "input", {
                        "input--error":hasError
                    })} type='password' id='txtPassword' required placeholder='Enter password' name="password" />
                    </div>

                    {hasError === true && (
                        <div className="form-group login__error">
                            <span className={classNames("label", {"label--error":hasError})}>{errorMessage}</span>
                        </div>
                    )}

                    <div className='form-group login__options'>
                        <div>
                            <input type='radio' id="radioOptIn" name="optIn" />

                            <label className='label radio-label' htmlFor='radioOptIn'>Keep me logged in
                            </label>
                        </div>

                        <div>
                            <Link className='link' to="/forgot-password">Forgot password?</Link>
                        </div>
                    </div>

                    <div className="center" style={{marginTop:"30px"}}>
                        {submitting === false && (
                            <button className='btn btn-primary login__btn'>
                                Sign in
                            </button>
                        )}

                        {submitting === true && (
                            <Spinner />
                        )}
                    </div>
                </form>

                {/* <div className='login__other-methods'>
                    <a href='https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86cramxaw66v3z&redirect_uri=https%3A%2F%2Fwww.leadrpro.com%2Flinkedin%2Fredirect&state=foobar&scope=r_liteprofile%20r_emailaddress' target='_blank'>
                    <div className='login__other-methods-linkedin'><FaLinkedin /> <span className='login__other-methods-label'>Sign in using LinkedIn</span></div>
                    </a>
                </div> */}

                <p className="login__register-link center">Don't have an account? <Link className='link' to="/join-now">Create Free Account</Link></p>

                </div>
            </div>
        </div>
        </FadeIn>
    );
}

export default Login;