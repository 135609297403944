import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { adminSearchUsers } from '../../utils/api';
import { AdminUserCountBreakdown, deleteUser, setAsTestUser, getAdminLatestUsers, getUsersByCompanySizeTable } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaCheckCircle, FaDownload, FaEllipsisV, FaEnvelope, FaGlobe, FaLinkedin, FaMap, FaRegEnvelope, FaRegMap, FaTimes, FaTimesCircle } from 'react-icons/fa';

function Users() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [buyerCount, setBuyerCount] = useState(0);
  const [breakdown, setBreakdown] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showSetTestConfirm, setShowSetTestConfirm] = useState(false);
  const [sizeBreakdown, setSizeBreakdown] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getAdminLatestUsers();

      if (res.success){
        setUsers(res.data);
        setLoaded(true);
      }
    }

    async function loadSizeBreakdown(){
      let res = await getUsersByCompanySizeTable();

      if (res.success)
      {
        setSizeBreakdown(res.data);
      }
    }

    async function loadDataBreakdown(){
      let res = await AdminUserCountBreakdown();

      if (res.success){
        setBreakdown(res.data[0]);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await AdminUserCountBreakdown();

        if (res.success){
          setBreakdown(res.data[0]);
          setLoaded(true);
        }
      }
    }

    loadData();
    loadDataBreakdown();
    loadSizeBreakdown();
  }, []);

  async function loadData(){
    let res = await getAdminLatestUsers();

    if (res.success){
      setUsers(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getAdminLatestUsers();

      if (res.success){
        setUsers(res.data);
        setLoaded(true);
      }
    }
  }

  async function handleSearch(){
    if (query.length === 0){
      loadData();
      return;
    }

    let id = null;

    if (Number.isInteger(parseInt(query)))
      id = parseInt(query);

    let res = await adminSearchUsers(query, id);

    if (res.success){
      setUsers(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await adminSearchUsers(query, id);

      if (res.success){
        setUsers(res.data);
        setLoaded(true);
      }
    }
  }

  async function handleKeyUp(k){
    if (k.key === 'Enter'){
      await handleSearch();
    }
  }

  async function handleConfirmDelete(){
    let res = await deleteUser(selectedUserId);

    if (res.success){
      setShowDeleteConfirm(false);
      setShowSubMenu(false);
      await loadData();
    }
  }

  async function handleSetTest(){
    let res = await setAsTestUser(selectedUserId);

    if (res.success){
      setShowSetTestConfirm(false);
      setSelectedUserId(null);
      setShowSubMenu(false);
      await loadData();
    }
  }

  const exportToCsv = () => {
    let data = users.map(item => {
      return {firstname: item.first_name, lastname: item.last_name, email: item.email}
    });
    const headers = Object.keys(data[0]).join(',') + '\n'; // Get CSV headers
    const rows = data.map(row => Object.values(row).join(',')).join('\n'); // Get CSV rows
    const csvString = headers + rows;

    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const timestamp = new Date().toISOString().replace(/[:.-]/g, ''); // Generate a timestamp
    const filename = `data_${timestamp}.csv`;

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Helmet>
        <title>Admin - Users</title>
      </Helmet>

      <div>
        {users !== null && loaded == true && (
          <>
            <h1>Users {`(${users.length})`} <span onClick={() => exportToCsv()} className='cursor-pointer ml-4 px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'><FaDownload /> <span className='ml-10'>Export</span></span></h1>
          </>
        )}
      </div>

      <div>
        <div className='admindashboard__search-lookup'>
          <input type='text' placeholder='Search...' className='input' value={query} onChange={(e) => setQuery(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} />

          <div className='admindashboard__search-lookup-btn'>
            <button className='btn' onClick={() => handleSearch()}>Search</button>
          </div>
        </div>
      </div>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && users.length === 0 && (
        <div className='center mt-20'><strong>Nothing to see here...</strong></div>
      )}

      {loaded && users.length > 0 && (
         <div>
          <table className='table admindashboard__table'>
            <tr>
              <th>Joined On</th>
              <th>User</th>
              <th>Roles</th>
              <th>Interviews completed</th>
              <th></th>
            </tr>

            {users.map((item, index) => (
              <tr key={index} className='relative'>

                <td>
                  {new Date(item.date_created).toLocaleDateString()}
                </td>

                <td>
                  <div>
                    <div style={{fontSize: '20px'}} className='bold'>
                      {item.email} {item.is_verified && (<span><FaCheckCircle style={{color: 'mediumseagreen'}} /></span>)}

                      {item.is_verified === false && (
                        <span><FaTimesCircle style={{color: 'red'}} /></span>
                      )}
                    </div>
                  </div>

                  <div>
                  <strong>#{item.registered_user_id}</strong>
                  </div>
                  <div>
                    <strong>Name: </strong> {item.first_name} {item.last_name}
                  </div>

                  <div>
                    <strong>Title: </strong> {item.job_title}
                  </div>

                  <div>
                    <strong>Country: </strong>{item.country_name && item.country_name.length > 0 ? item.country_name : <i>Not set</i>}
                  </div>

                  <div>
                    <strong>Tz: </strong> {item.timezone}
                  </div>

                  {item.work_email && (
                    <div><strong>Work: </strong> <span className='admindashboard__green-flag'>{item.work_email}</span></div>
                  )}

                  {item.work_email === null && (
                    <div><strong>Work:</strong> <span className='admindashboard__red-flag'>Not found</span></div>
                  )}

                  {item.linkedin_url && (
                  <div>
                    <strong>LI: </strong>

                    {item.linkedin_url && item.linkedin_url.length > 0 && (
                      <span className='ml-10'><a href={item.linkedin_url}>LinkedIn</a></span>
                    )}

                  {(item.linkedin_url === null || item.linkedin_url.length === 0) && (
                      <span className='ml-10'><i>Not set</i></span>
                    )}
                  </div>
                  )}
                </td> 

                <td>
                  <div>
                    {item.is_buyer && (
                      <span className='admindashboard__role-tag--buyer'>Buyer</span>
                    )}

                    {item.is_seller && (
                      <span className='admindashboard__role-tag--seller'>Seller</span>
                    )}

                    {item.is_event_host && (
                      <span className='admindashboard__role-tag--host'>Host</span>
                    )}

                    {item.is_event_attendee && (
                      <span className='admindashboard__role-tag--attendee'>Events</span>
                    )}

                    {item.is_test_account && (
                      <span className='admindashboard__red-flag'>Test</span>
                    )}
                  </div>
                </td>

                <td>
                  {item.interviews_completed}
                </td>

                <td>
                  <div>
                    <div className='relative'>
                      <span className='admindashboard__demo-card-submenu-icon' onClick={() => {
                        if (selectedUserId != null && selectedUserId === item.registered_user_id){
                          setShowSubMenu(!showSubMenu);
                        }

                        else{
                          setShowSubMenu(true);
                          setSelectedUserId(item.registered_user_id);
                        }
                      }}>
                        <FaEllipsisV />
                      </span>

                      {showSubMenu == true && selectedUserId == item.registered_user_id && (
                        <FadeIn delay={100} duration={100}>
                          <div className='dashboard__submenu'>
                            <div className='dashboard__submenu-item' onClick={() => {
                              setSelectedUserId(item.registered_user_id);
                              setShowSetTestConfirm(true);
                            }}>Set as test account</div>

                            <div className='dashboard__submenu-item' onClick={() => {
                              setSelectedUserId(item.registered_user_id); setShowDeleteConfirm(true);

                            }}>Delete account</div>
                          </div>
                        </FadeIn>
                        )}
                      </div>
                    </div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}

      {showDeleteConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowDeleteConfirm(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Delete user?
            </h2>

            <p className='mt-10 mb-10'>
              This user will be completely removed from the site.
            </p>

            <div className='mt-20 center'>
              <button className='btn' onClick={() => handleConfirmDelete()}>Confirm</button>
            </div>
          </div>
        </>
      )}

      {showSetTestConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowSetTestConfirm(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Set as test account
            </h2>

            <div className='mt-20 center'>
              <button className='btn' onClick={() => handleSetTest()}>Set as test account</button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Users;