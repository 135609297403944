import React from 'react';
import { useState, useEffect } from 'react';

const loadBabel = (callback) => {
  const script = document.createElement("script");
  script.src = "https://unpkg.com/@babel/standalone/babel.min.js";
  script.onload = callback;
  document.head.appendChild(script);
};

const DynamicTemplateRenderer = ({ templateJsx, data, isModifying, template_style }) => {
  const [isBabelLoaded, setIsBabelLoaded] = useState(false);
  const [transpiledCode, setTranspiledCode] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Load Babel dynamically
    loadBabel(() => {
      console.log("Babel loaded!");
      setIsBabelLoaded(true);
    });
  }, []);

  useEffect(() => {
    //console.log(isBabelLoaded, templateJsx, isModifying);
    if (isBabelLoaded && templateJsx && isModifying == false) {
      // Transpile the JSX after Babel is loaded
      try{
      const transpiled = window.Babel.transform(templateJsx, {
        presets: ["react"]
      }).code;
    
      setTranspiledCode(transpiled);
      setHasError(false);
      }

      catch(error){
        console.log('has error', error);
        setHasError(true);
      }
    }
  }, [isBabelLoaded, templateJsx, isModifying]);

   //if (!isBabelLoaded) return <div>Loading Babel...</div>;
   if (!transpiledCode) return <div style={{textAlign: 'center', fontSize: '11px', fontWeight: 'bold'}}>Generating...</div>;
  // if (hasError){
  //   return <div>Invalid</div>;
  // }

  //console.log('transpired',transpiledCode);

  if (template_style != null && template_style.length > 0)
    injectCSS(template_style);

  // Create a function to render the transpiled code
  if (isModifying){
    return <div><i>Modifying...</i></div>
  }
  const RenderedTemplate = new Function("React", "data", `return ${transpiledCode}`);
  //const component = RenderedTemplate(React);
//console.log(component)
//console.log('data', data);
  return RenderedTemplate(React, data);
};

const injectCSS = (css) => {
  const style = document.createElement("style");
  style.type = "text/css";
  style.appendChild(document.createTextNode(css));
  document.head.appendChild(style);
};

const fetchTemplateWithCSS = async (templateId) => {
  const response = await fetch(`/api/templates/${templateId}`);
  const { css, jsx } = await response.json();
  return { css, jsx };
};

// Usage:
function OutputTypeRenderer({ template, templateId, template_style, aiData, isModifying }) {
 // const [template, setTemplate] = React.useState(null);

  // React.useEffect(() => {
  //   fetchTemplateWithCSS(templateId).then(({ css, jsx }) => {
  //     injectCSS(css); // Inject the CSS
  //     setTemplate(jsx); // Set the JSX template
  //   });
  // }, [templateId]);
  // template = `React.createElement("div", { style: { color: "red" } }, data.content)`;
// template = '<div>hello world</div>';
  // if (template) return <div>Loading Template...</div>;
  let data = {};

  if (aiData.length > 0 && isModifying == false){
    try{
      data = JSON.parse(aiData);
    }

    catch(error){
      data = {};
    }
  }

  return (
    <DynamicTemplateRenderer templateJsx={template} template_style={template_style} data={data} isModifying={isModifying} />
  );
};

export default OutputTypeRenderer;