import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getJourneyInterviews, getInterviewContent } from '../../utils/api/admin';

function Interviews() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const [selectedContent, setSelectedContent] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getJourneyInterviews();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleShowContent(id){
    setSelectedId(id);
    let res = await getInterviewContent(id);

    if (res.success){
      setShowContent(true);
      setSelectedContent(res.data);
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Interviews</title>
      </Helmet>

      <h1>Journey Interviews {`(${data.length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th>Date Created</th>
              <th>Journey</th>
              <th>Interview</th>
              <th>Name</th>
              <th>Email</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Content Pieces</th>
              <th>Rating</th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td>{new Date(item.date_created).toLocaleString()}</td>
                <td>{item.journey_name}</td>
                <td>{item.name}</td>
                <td>{item.first_name} {item.last_name}</td>
                <td>{item.email}</td>
                <td>{item.cost}</td>
                <td>
                  {item.status == 'Completed' && (
                    <span className='admindashboard__green-flag'>Completed</span>
                  )}

                  {item.status == 'Pending' && (
                    <span className='admindashboard__yellow-flag'>Pending</span>
                  )}
                </td>
                <td>
                  <button className='btn' onClick={()=> handleShowContent(item.journey_step_interview_id)}>{item.content_count}</button>
                </td>
                <td>{item.rating}</td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showContent && (
        <>
          <div className='overlay'></div>
          <div className='modal admindashboard__modal--full'>
            <div className='modal__close' onClick={() => setShowContent(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Generated Content</h2>

            <div className='mt-10'>
              {selectedContent.map(item => (
                <div className='admindashboard__card'>
                  {item.content_structured_data}
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default Interviews;