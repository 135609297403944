import './Terms.css';
import React, { useEffect } from 'react';
import ScrollToTop from '../../components/layout/ScrollToTop';

function Terms_S03012023() {
  useEffect(() => {
    document.title = 'Terms and Conditions';
  }, []);

  return (
    <div className='terms'>
      <ScrollToTop />
      <h1>Terms and Conditions</h1>

      <div>Last Updated: 11/24/2024</div>

      <h2>INTRODUCTION</h2>

      <p>
        The following Seller Terms of Use (the “Seller Terms”) are entered into
        by and between you, the “Seller” and {process.env.REACT_APP_SITE_NAME}, Inc. ({process.env.REACT_APP_SITE_NAME},” “we,”
        “our,” or “us”). These Terms govern your access to and use of
        https://{process.env.REACT_APP_SITE_DOMAIN} along with related features, content,
        applications, and products (collectively, the “Platform”) as a Seller.
        These Seller Terms are provided in addition to our Website Terms of Use
        found here, which also apply to your use of the Platform. Any terms not
        defined in these Seller Terms have the meanings provided in the Website
        Terms of Use.
      </p>

      <h2>ACCEPTANCE OF THESE TERMS</h2>

      <p>
        Please read these Seller Terms carefully before you register to become a
        Seller on our Platform. By registering as a Seller, you acknowledge that
        you have read, understood, and agree to these Seller Terms. You also
        represent and warrant that you are legally authorized to do so, and
        agree to be bound by these Seller Terms. If you are using the service on
        behalf of an organization or legal entity (“Organization”), that you are
        agreeing to these Seller Terms on behalf of that organization, and you
        represent and warrant that you have the authority to bind that
        Organization to these Terms. In that case, “you” and “yours” refers to
        you and that Organization. PLEASE NOTE THE “ARBITRATION” AND “DISPUTE
        RESOLUTION” PROVISIONS OF OUR WEBSITE TERMS OF USE APPLY TO THESE SELLER
        TERMS.
      </p>

      <h2>CHANGES TO THESE SELLER TERMS</h2>

      <p>
        We may revise and update these Seller Terms from time to time in our
        sole discretion. All changes are effective immediately when we post
        them, and apply to all access to and use of the Platform as Seller. Your
        continued use of the Platform following the posting of revised Seller
        Terms means that you accept and agree to the changes. If you do not
        agree to these Terms or any modifications to these Seller Terms, do not
        use our Platform.
      </p>

      <h2>ELIGIBILITY</h2>
      <p>
        Only individuals who are at least 18 years old and can form legally
        binding contracts can register as Seller on our Platform. By using the
        Platform as a Seller, you represent and warrant that you meet all
        eligibility requirements we outline in our Terms and Conditions. We
        reserve the right to refuse any individual to register as a Seller on
        our Platform.
      </p>

      <h2>PRICING DETAILS</h2>

      <p>
        Pricing details for Sellers can be reviewed here:
        https://${process.env.REACT_APP_SITE_DOMAIN}/pricing. Pricing is based on various
        functionalities provided to Sellers. {process.env.REACT_APP_SITE_NAME} reserves the right to
        update the Platform and subscription offerings at any time and in our
        sole discretion. In the event that the pricing and functionality of your
        plan is affected, {process.env.REACT_APP_SITE_NAME} will notify you of those changes.
      </p>

      <h2>SELLER ACCOUNTS</h2>

      <p>
        Sellers are able to connect with individuals using our Platform and can
        provide individuals with demos and information regarding their products
        and services. If you are selling products and services on our Platform
        on behalf of an Organization, you must provide information regarding
        your Organization. Sellers are responsible for their interactions with
        individuals and agree that they will only provide Buyers with truthful
        information regarding their products and services. Sellers will need to
        register under a Seller Account in order to use our Platform. We'll need
        your username, email, and a password to sign up for the Platform.
        Sellers agree to provide accurate information to register for a Seller
        Account.
      </p>

      <h2>PAYMENTS</h2>
      <p>
        Terms of payment are within our sole discretion. Invoices are due and
        payable on the date specified on the invoice. We may charge a late
        payment penalty per month on undisputed amounts, or the maximum rate
        permitted by law, whichever is less. Without waiving any of our other
        rights or remedies, we may refuse additional orders and suspend any
        services until all overdue amounts are paid in full. All payments by
        Sellers to {process.env.REACT_APP_SITE_NAME} will be: (i) written and documented in agreements
        with {process.env.REACT_APP_SITE_NAME}, (ii) specified within the Platform; or (iii) a
        combination of two. Transactions between Sellers and Buyers will be: (i)
        managed using features of our Platform; (ii) facilitated by {process.env.REACT_APP_SITE_NAME} in
        separate agreements; (iii) agreed directly between such Sellers and
        Buyers; or (iv) a combination of the foregoing. We reserve the right to
        offset any payments made to a Seller against any fees owed to {process.env.REACT_APP_SITE_NAME}.
        For more information on payments and fees please review the “Fees”
        section of our Terms and Conditions.
      </p>

      <h2>ADD-ON SERVICES</h2>
      <p>
      {process.env.REACT_APP_SITE_NAME} has several add-on services that are available at an additional
        cost and will be charged separately. At present, {process.env.REACT_APP_SITE_NAME} relies
        entirely on Stripe, Inc. to manage any credit card or debit card payment
        functions. Payment due for the {process.env.REACT_APP_SITE_NAME} Services is subject to the terms
        of the online order process at https://stripe.com/terms/US. {process.env.REACT_APP_SITE_NAME}
        does not store, process or transmit any of your credit card or debit
        card data. You are responsible for the timely payment of all products
        and services you purchase through your account. You consent to a $1
        verification charge upon addition of a new card to your account to be
        refunded upon completion of verification. We will refund you within a
        reasonable amount of time after we receive the verification charge
        (usually within 48 hrs). You are responsible for all transactions
        (one-time, recurring, and refunds) processed through any third party
        payment provider. We are not liable for loss or damage from errant or
        invalid transactions processed by any third party payment provider. This
        includes transactions that were not processed due to a network
        communication error, or any other reason. If you enter into a
        transaction, it is your responsibility to verify that the transaction
        was successfully processed. You understand and agree that you will not
        hold {process.env.REACT_APP_SITE_NAME} liable for any adverse effects that actions (whether
        intentional or unintentional) on the part of any third party payment
        provider may cause to your {process.env.REACT_APP_SITE_NAME} account or your business.
      </p>

      <h2>COMPLIANCE</h2>
      <p>
        You agree that any products or services you provide on our Platform
        will: (i) comply with all applicable laws and regulations and be
        operated in accordance with a comprehensive privacy policy that is
        consistent with industry standards and discloses whether any third
        parties may gather and use the personal information of your users. We
        reserve the right to refuse or remove any products or services provided
        by Sellers on our Platform at any time and without notice, for any
        reason, in our sole discretion.
      </p>

      <h2>DISCLAIMER</h2>
      <p>
        Our Platform is provided “as is.” {process.env.REACT_APP_SITE_NAME} makes no representations or
        warranties that the Platform will be suitable for your needs is
        complete, timely, reliable, or free from errors, inaccuracies or
        typographical mistakes. Furthermore, we make no representations or
        warranties regarding the amount of inventory, fees or placements that
        the Platform will generate, or the results or monetization of any
        products and services you provide on the Platform. {process.env.REACT_APP_SITE_NAME} disclaims
        all warranties, express or implied, including warranties of
        merchantability or fitness for a particular purpose or non-infringement
        of the rights of others. We do not warrant that the Platform will be
        free of errors, viruses, or other destructive or harmful code.
      </p>

      <h2>SEVERABILITY</h2>
      <p>
        If any part of these Seller Terms is invalid, void, or for any reason
        unenforceable, that part will be deemed severable and limited or
        eliminated to the minimum extent necessary. The limitation or
        elimination of the part will not affect any other terms.
      </p>

      <h2>QUESTIONS OR COMMENTS</h2>
      <p>
        Please send us a message on our contact page if you have any questions
        regarding these Seller Terms at: {process.env.REACT_APP_SITE_DOMAIN}/contact/
      </p>
    </div>
  );
}

export default Terms_S03012023;
