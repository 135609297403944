import {useState,useEffect} from 'react';
import './ContentStudio.css';
import { useLocation } from 'react-router-dom';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, getPreferredName, getCompletedInterviews, getJourneysWithCompleted,getOutputTypesForInterview } from '../../utils/api';
import { FaArrowLeft, FaBrain, FaBriefcase, FaCalendarAlt, FaCloud, FaLock, FaRegCalendarCheck, FaRegLightbulb, FaSoundcloud, FaSpinner, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import * as FaIcons from 'react-icons/fa';

const ContentStudio = () => {
  const [preferredName, setPreferredName] = useState('');
  const [loadedName, setLoadedName] = useState(false);
  const [journeys, setJourneys] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadedJourneys, setLoadedJourneys] = useState(false);
  const [selectedJourneyId, setSelectedJourneyId] = useState(null);
  const [selectedInterviewId, setSelectedInterviewId] = useState(null);
  const [outputTypes, setOutputTypes] = useState([]);
  const [loadedOutputTypes, setLoadedOutputTypes] = useState(false);
  const navigate = useNavigate();

  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0){
      return <p>No icon.</p>
    }
  
    // Dynamically get the icon component from the FaIcons object
    const IconComponent = FaIcons[iconName];
  
    // If the icon is found, render it
    if (IconComponent) {
      return <IconComponent />;
    }
  
    // Fallback if icon is not found
    return <p>Icon not found</p>;
  };

  useEffect(() => {
    async function loadInterviews(){
      let res = await getCompletedInterviews(1);

      if (res.success){
        setInterviews(res.data);
        setLoaded(true);
      }
    }

    async function loadJourneys(){
      let res = await getJourneysWithCompleted();

      if (res.success){
        setJourneys(res.data);
        setLoadedJourneys(true);
      }
    }

    async function loadName(){
      let res = await getPreferredName();

    if (res.success){
      setPreferredName(res.data);
      setLoadedName(true);
    }
    }
    loadName();
    loadJourneys();
    loadInterviews();
  }, []);

  async function handleClick(id){
    navigate(`/account/studio/${selectedInterviewId}/${id}`);
  }

  async function handleInterviewClick(id){
    setSelectedInterviewId(id);

    let res = await getOutputTypesForInterview(id);

    if (res.success){
      setOutputTypes(res.data);
      setLoadedOutputTypes(true);
    }
  }

  async function handleJourneyClick(id, item){
    setSelectedJourneyId(id);
  }

  return (
    <div className='contentstudio'>
      <h1>Content Studio</h1>

      <div className="contentstudio__copy">
        View, manage and edit your generated content.
      </div>

      {(loaded == false || loadedJourneys == false) && (
        <div className="center">
            <Spinner />
        </div>
      )}

      <div>
        {loaded && interviews.length == 0 && (
          <>
              <div>
                <i>You have not generated any content yet. Complete your <Link className="link" to={'/journeys'}>first interview</Link> to get started.</i>
              </div>
          </>
        )}

        {loadedJourneys && journeys.length > 0 && selectedJourneyId == null && (
          <>
            <div className='dashboard__journey-items mt-10'>
              {journeys.map((item, index) => (
                <div className='dashboard__journey-item' style={{
                  backgroundImage: `url("${item.image_url}")`,
                  backgroundSize: 'cover'
                }} onClick={() => handleJourneyClick(item.journey_id, item)}>
                  <div className='dashboard__journey-item-body'>
                    <div className='dashboard__journey-item-name'>
                      {index + 1}. {item.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {loaded && selectedJourneyId != null && selectedInterviewId == null && (
          <>
            <div className='mb-20'>
            <span className='mb-20 link' onClick={() => setSelectedJourneyId(null)}>
              <FaArrowLeft /> Back to journeys</span>
          </div>
          </>
        )}

        {loaded && interviews.length > 0 && selectedJourneyId != null 
        && selectedInterviewId == null
        && interviews.filter(x => x.journey_id == selectedJourneyId).map((item, index) => (
          <FadeIn delay={300} duration={300}>
            <div className='contentstudio__item dashboard__journey' onClick={()=> handleInterviewClick(item.call_id)} style={{
              backgroundImage: `url("${item.image_url}")`,
              backgroundSize: 'cover'
            }}>
    
            <div className='dashboard__journey-title'>
              <h2>{index + 1}. {item.name}</h2>
    
              <div className='dashboard__journey-description'>
                 {item.description}
              </div>

              <div className='dashboard__journey-interview-date'>
                <strong>Interview date:</strong> {new Date(item.date_created).toLocaleString()}
              </div>

              {/* <div className='dashboard__journey-description'>
                Interview length: {item.min_duration}-{item.max_duration} mins
              </div> */}
            </div>
          </div>
          </FadeIn>
          ))}
      </div>

      {selectedInterviewId != null && loadedOutputTypes == false && (
        <Spinner />
      )}

      {loaded == true && loadedOutputTypes == true && selectedInterviewId != null && (
        <FadeIn delay={300} duration={300}>
          <div className='mb-20'>
            <span className='mb-20 link' onClick={() => setSelectedInterviewId(null)}>
              <FaArrowLeft /> Back to interviews</span>
          </div>

          <h3>What type of content do you want to generate?</h3>

          <div className='contentstudio__output-types'>
            {outputTypes.map(item => (
              <div className='contentstudio__output-type' onClick={(e) => handleClick(item.output_type_id)}>
                <div style={{display: 'flex', alignItems: 'center'}}><span style={{fontSize: '20px'}}><IconDisplay iconName={item.fa_icon} /></span> <span className='contentstudio__output-type-name ml-10'>{item.name}</span></div>

                <div className='contentstudio__output-type-description'>
                  {item.description}
                </div>
              </div>
            ))}
          </div>
        </FadeIn>
      )}
    </div>
  );
};

export default ContentStudio;
