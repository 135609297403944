import React, { useState, useEffect } from 'react';
import close from '../../assets/design/pricing/closing-x.png';
import calendar from '../../assets/design/dashboard/calendar.png';
import './JourneyInterviewContentItem.css';
import { getAllContent, getInterviewOutputTypes } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import * as FaIcons from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';

const JourneyInterviewContentItem = () => {
  //state variables
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  let {id} = useParams();
  const navigate = useNavigate();

    const IconDisplay = ({ iconName }) => {
      if (iconName == null || iconName.length == 0){
        return <p>No icon.</p>
      }
    
      // Dynamically get the icon component from the FaIcons object
      const IconComponent = FaIcons[iconName];
    
      // If the icon is found, render it
      if (IconComponent) {
        return <IconComponent />;
      }
    
      // Fallback if icon is not found
      return <p>Icon not found</p>;
    };

  useEffect(() => {
    async function loadData(){
      let res = await getInterviewOutputTypes(id);

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  async function handleClick(val){
    navigate(`/account/studio/${id}/${val}`);
  }

  return (
    <>
      <div className='contentstudio'>
      <div className="flex">
              <button onClick={() => navigate('/content')} className="px-4 inline-flex items-center border-r border-gray-200">          
                <span className="ml-2 text-sm font-medium text-gray-700">Back</span>
              </button>

            </div>

        {loaded == false && (
          <Spinner />
        )}

        <h1>Content Types</h1>

        {loaded == true && (
          <div>
            <ul className='min-w-full divide-y divide-gray-200 dark:divide-neutral-700'>
              {data.map((item, index) => (
                <li 
                key={index} 
                className="cursor-pointer rounded-sm flex justify-between gap-x-6 px-5 py-5 bg-white hover:border-b-2 hover:border-gray-400 hover:pb-4 transition-all duration-200"
                onClick={() => handleClick(item.output_type_id)}
              >
              
              

                <div className="flex min-w-0 gap-x-4">
                  <IconDisplay iconName={item.fa_icon} />

                  <div className="min-w-0 flex-auto">
                    <p className="text-sm/6 font-semibold text-gray-900">{item.name}</p>
                  </div>
                </div>

                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  {item.count}
                </div>
              </li>
              ))}
            </ul>
          </div>
        )}
        
      </div>
    </>
  );
};

export default JourneyInterviewContentItem;
