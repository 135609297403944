
import './Examples.css';
import {useEffect, useState} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getPayoutTransactions, refreshToken, getUserProfile, getAllIndustries, getDepartmentsForFeed } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle, FaLinkedin, FaTwitter } from "react-icons/fa";
import close from '../../assets/design/dashboard/close.png';
import Spinner from '../../components/layout/Spinner';
import imgKathie from '../../assets/design/kathie.png';

import li1 from './assets/li1.png';
import li2 from './assets/li2.png';
import li3 from './assets/li3.png';

const Examples = () => {
  const [transactions, setTransactions] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [showPost, setShowPost] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      // based on users current role as buyer or seller
    async function loadUserProfile(){
      let response = await getUserProfile();

      if (response.success){
        let data = response.data;

        setUser(response.data);
        setLoading(false);
      }

      else if (response.status === 403){
        let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let response = await getUserProfile();

            if (response.success){
              let data = response.data;

              setUser(response.data);
              setLoading(false);

              let role = data.roles.filter(item => item.role_type_id === 1)[0];
            // setSelectedPersonaType({persona_type_id: role.persona_type_id, name: role.persona_type, icon_image: role.icon_image});
            }
          }
      }
    }

    async function loadIndustries(){
      let res = await getAllIndustries();

      if (res.success){
        setIndustries(res.data);
      }
    }

    async function loadDepartments(){
      let res = await getDepartmentsForFeed();

      if (res.success){
        setDepartments(res.data);
        setLoading(false);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let res = await getDepartmentsForFeed();

            if (res.success){
              setDepartments(res.data);
              setLoading(false);
            }
          }
      }
    }

    loadUserProfile();
   // loadIndustries();
    loadDepartments();
  }, []);

  return (
    <>
      <Helmet>
        <title>Examples</title>
      </Helmet>

    <div className="examples">
      <div className='examples__hero'>
        <div className='examples__hero-left'>
          <div className='examples__hero-title'>
            Generate Unique Content That Matches Your Voice
          </div>

          <div className='examples__hero-subtitle'>
            <strong>Get Started for Free</strong>
          </div>

          <div className='examples__hero-cta'>
            <Link to={'/join-now'} className='btn btn--primary'>Get Started</Link>
          </div>
        </div>

        <div className='examples__hero-kathie'>
          <img src={imgKathie} alt='' />
        </div>
      </div>

      <section className='examples__output-types-wrapper'>
        <h2 className='center'>Instantly Generate</h2>
        <div className='examples__output-types'>
          <div className='examples__output-type'>
            <FaLinkedin /> LinkedIn Posts
          </div>

          <div className='examples__output-type'>
            <FaTwitter /> Twitter/X Posts
          </div>

          <div className='examples__output-type'>
            <FaRegEdit /> Blog Posts
          </div>

          <div className='examples__output-type'>
            <FaLinkedin /> LinkedIn Posts
          </div>
        </div>
      </section>

      <section className='examples__section'>
        <h2>Engaging Social Media Content</h2>
        <div className='examples__items'>
          <div className='examples__item' data-aos='fade-up'>
            <img src={li1} alt='' />
          </div>

          <div className='examples__item' data-aos='fade-up'>
            <img src={li2} alt='' />
          </div>

          <div className='examples__item' data-aos='fade-up'>
            <img src={li3} alt='' />
          </div>
        </div>
      </section>

      <section className='examples__section'>
        <h2>Thought Provoking Blog Articles</h2>
        <div className='examples__items'>
          <div className='examples__item' data-aos='fade-up'>
            <img src={li1} alt='' />
          </div>

          <div className='examples__item' data-aos='fade-up'>
            <img src={li2} alt='' />
          </div>

          <div className='examples__item' data-aos='fade-up'>
            <img src={li3} alt='' />
          </div>
        </div>
      </section>

      <section className='examples__section examples__bottom-cta'>
        sldkj
      </section>
    </div>

    {showPost && (
      <>
        <div className='overlay'></div>

        <div className='modal feed__modal-post'>
          <div className='modal__close' onClick={() => setShowPost(false)}>
            <img src={close} />
          </div>
          
          <div className='feed__modal-post-header'>
            Start a conversation
          </div>

          <div>
            <textarea className='input feed__textarea' placeholder='What would you like to ask/answer/post?'>

            </textarea>
          </div>

          <div>
            <button className='feed__modal-post-btn'>Post</button>
          </div>
        </div>
      </>
    )}
    </>
  )
}

  export default Examples;