import React, { useState, useEffect } from 'react';
import close from '../../assets/design/pricing/closing-x.png';
import calendar from '../../assets/design/dashboard/calendar.png';
import './JourneyInterviewContent.css';
import { getAllContent, getCompletedInterviews, getUserOutputTypes, getInterviewsForOutputType, getContentByOutputType } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import * as FaIcons from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import FadeIn from '../../utils/fadein';
import Markdown from 'react-markdown';


const JourneyInterviewContent = () => {
  //state variables
  const [data, setData] = useState([]);
  const [outputTypes, setOutputTypes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedOutputTypeId, setSelectedOutputTypeId] = useState(null);
  const [selectedOutputType, setSelectedOutputType] = useState(null);
  const [content, setContent] = useState([]);
  const navigate = useNavigate();

    const IconDisplay = ({ iconName }) => {
      if (iconName == null || iconName.length == 0){
        return <p>No icon.</p>
      }
    
      // Dynamically get the icon component from the FaIcons object
      const IconComponent = FaIcons[iconName];
    
      // If the icon is found, render it
      if (IconComponent) {
        return <IconComponent />;
      }
    
      // Fallback if icon is not found
      return <p>Icon not found</p>;
    };

  useEffect(() => {
    async function loadData(){
      let res = await getCompletedInterviews();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    async function loadOutputTypes(){
      let res = await getUserOutputTypes();

      if (res.success){
        setOutputTypes(res.data);
      }
    }

    loadData();
    loadOutputTypes();
  }, []);

  async function loadContent(id){
    let res = await getContentByOutputType(id);

    if (res.success){
      setContent(res.data);
    }
  }

  async function handleClick(item){
    navigate(`/account/studio/${item.call_id}/${selectedOutputTypeId}`);
  }

  async function handleOutputTypeClick(id, item){
    setSelectedOutputTypeId(id);
    setSelectedOutputType(item);
    setLoaded(false);

    let res = await getInterviewsForOutputType(id);

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }

    loadContent(id);
  }

  async function handleCopy(text, id){
    navigator.clipboard.writeText(text)
        .then(() => {
          document.getElementById('btncopy_' + id).innerHTML = 'Copied!';
          setTimeout(function(e) {
            document.getElementById('btncopy_' + id).innerHTML = `Copy <i class='fa fa-copy'></i>`;
          }, [1000])
           // alert("Text copied to clipboard!");
        })
        .catch(err => {
         //   alert("Failed to copy text.");
        });
  }

  return (
    <>
      <div className='contentstudio'>
        <h1 className="mb-4 mt-6 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">My Content</h1>

        <div className='' style={{
          fontSize: '14px',
          margin: '20px 0px'
        }}>
          <span><FaIcons.FaRegFolder /></span> <span className='ml-10'>Manage all of your content in one place.</span>
        </div>

        {loaded == false && (
          <Spinner />
        )}

          {selectedOutputTypeId != null && (
          <div>
              <span className='link' onClick={() => setSelectedOutputTypeId(null)}>
               <FaIcons.FaArrowLeft /> Back</span>
            </div>
          )}

        {outputTypes.length > 0 && (
          <FadeIn delay={300} duration={300}>
          <ul>
            {outputTypes.map((item, index) => (
              <>
                {(selectedOutputTypeId == null || selectedOutputTypeId == item.output_type_id) && (
                <li 
                key={index}
                style={{
                  margin: '5px',
                  borderBottom: 'solid 1px #ccc',
                  alignItems: 'center'
                }}
                className="cursor-pointer rounded-md flex justify-between gap-x-6 px-5 py-5 bg-white hover:border-b-2 hover:border-gray-400 hover:bg-gray-200 transition-all duration-200"
                onClick={() => handleOutputTypeClick(item.output_type_id, item)}
              >
              
                <div className="flex min-w-0 gap-x-4" style={{alignItems: 'center'}}>
                  <span style={{fontSize: '22px'}}><IconDisplay iconName={item.fa_icon} /></span>

                  <div className="min-w-0 flex-auto">
                    <p className="mb-2 text-1xl font-bold tracking-tight text-gray-900 dark:text-white">{item.name}</p>

                    <p className='mt-1 truncate text-xs/5 text-gray-500'>
                      {item.description}
                    </p>

                    <div className="mt-1 truncate text-xs/5 text-gray-500">
                  {item.count} items
                </div>
                  </div>
                </div>
              </li>
                )}
              </>
            ))}
          </ul>
          </FadeIn>
        )}

        {loaded == true && selectedOutputTypeId != null && (
          <FadeIn delay={300} duration={300} className='mt-30'>
            <h2 className='mb-4 mt-6 text-2xl font-bold tracking-tight text-gray-900 dark:text-white items-center flex'><FaIcons.FaMicrophoneAlt /> <span className='ml-10'>Completed Interviews with <span className='bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent'>{selectedOutputType.name}</span></span></h2>
            <ul className='my-4 min-w-full divide-y divide-gray-200 dark:divide-neutral-700'>
              {data.map(item => (
                <li 
                key={item.date_created}
                style={{
                  margin: '5px',
                  borderBottom: 'solid 1px #ddd'
                }}
                className="relative cursor-pointer border-b-2 border-grey-500 rounded-md flex justify-between gap-x-6 px-5 py-5 bg-white hover:border-gray-400 hover:bg-gray-200 transition-all duration-200"
                onClick={() => handleClick(item)}
              >
              
                <div className="flex min-w-0 gap-x-4">
                  {/* <IconDisplay iconName={item.output_type_icon} /> */}

                  <div className="min-w-0 flex-auto">
                    <p className="text-sm/4 font-normal text-gray-900">{item.journey_name}</p>
                    <p className="mb-2 text-1xl font-bold tracking-tight text-gray-900 dark:text-white">{item.name}</p>

                    <p className="mt-1 truncate text-xs/5 text-gray-500">{item.content_count} items</p>

                    <p className="mt-1 truncate text-xs/5 text-gray-500">
                    <FaIcons.FaRegHeart /> {item.liked_count}</p>
                  </div>
                </div>

                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  {item.date_created ? (
                    <p className="mt-1 text-xs/5 text-gray-500">
                      <FaIcons.FaMicrophoneAlt /> Interview date: <time dateTime={item.date_created}>{new Date(item.date_created).toLocaleString()}</time>
                    </p>
                  ) : (
                    <div className="mt-1 flex items-center gap-x-1.5">
                      <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                        <div className="size-1.5 rounded-full bg-emerald-500" />
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className='absolute bottom-2 right-2 bg-gray-200 p-1 rounded-sm'>
                  <FaIcons.FaThumbtack/>
                </div> */}
              </li>
              ))}
            </ul>
          </FadeIn>
        )}

        {loaded == true && selectedOutputTypeId != null && content.length > 0 && (
          <FadeIn delay={300} duration={300}>
<h2 className='my-6 text-1xl font-bold'>Latest {selectedOutputType.name} Content</h2>
            <ul className='my-4 min-w-full divide-y divide-gray-200 dark:divide-neutral-700'>
              {content.slice(0, 5).map(item => (
                <li 
                key={item.date_created}
                style={{
                  margin: '5px',
                  borderBottom: 'solid 1px #ddd'
                }}
                className="relative border-b-2 border-grey-500 rounded-md flex justify-between gap-x-6 px-5 py-5 bg-white hover:border-gray-400 hover:bg-gray-200 transition-all duration-200"
              >
              
                <div className="flex min-w-0 gap-x-4">
                  {/* <IconDisplay iconName={item.output_type_icon} /> */}

                  <div className="min-w-0 flex-auto">
                    <p className='mt-1 truncate text-xs/5 text-gray-500'>
                      Date Generated: {new Date(item.date_created).toLocaleString()}
                    </p>

                    <p className="mt-1 truncate text-xs/5 text-gray-500">
                      <Markdown>{item.markdown_content}</Markdown>
                    
                    </p>

                    {/* <p className="mt-1 truncate text-xs/5 text-gray-500">
                    <FaIcons.FaRegHeart /></p> */}

                    <div className="mt-1">
                      
                      <button id={`btncopy_${item.journey_interview_content_id}`} className='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-4 border border-gray-400 rounded-md shadow' onClick={() => handleCopy(item.markdown_content, item.journey_interview_content_id)}>Copy</button>
                    </div>
                  </div>
                </div>
{/* 
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  {item.date_created ? (
                    <p className="mt-1 text-xs/5 text-gray-500">
                      <FaIcons.FaMicrophoneAlt /> Interview date: <time dateTime={item.date_created}>{new Date(item.date_created).toLocaleString()}</time>
                    </p>
                  ) : (
                    <div className="mt-1 flex items-center gap-x-1.5">
                      <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                        <div className="size-1.5 rounded-full bg-emerald-500" />
                      </div>
                    </div>
                  )}
                </div> */}

                {/* <div className='absolute bottom-2 right-2 bg-gray-200 p-1 rounded-sm'>
                  <FaIcons.FaThumbtack/>
                </div> */}
              </li>
              ))}
            </ul>
          </FadeIn>
        )}
      </div>
    </>
  );
};

export default JourneyInterviewContent;
