import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import congrats from '../../assets/design/dashboard/congrats.png';
import { getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, getDemoQualifierQuestions, answerQualifierQuestion } from '../../utils/api';

function FAQ() {

return (
  
<div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <div className="max-w-2xl mx-auto mb-10 lg:mb-14">
    <h2 className="text-2xl font-bold md:text-4xl md:leading-tight">You might be wondering...</h2>
  </div>

  <div className="max-w-2xl mx-auto divide-y divide-gray-200">
    <div className="py-8 first:pt-0 last:pb-0">
      <div className="flex gap-x-5">
        <svg className="shrink-0 mt-1 size-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

        <div className="grow">
          <h3 className="md:text-lg font-semibold text-gray-800">
            Can I cancel at anytime?
          </h3>
          <p className="mt-1 text-gray-500">
            Yes, you can cancel anytime no questions are asked while you cancel but we would highly appreciate if you will give us some feedback.
          </p>
        </div>
      </div>
    </div>

    <div className="py-8 first:pt-0 last:pb-0">
      <div className="flex gap-x-5">
        <svg className="shrink-0 mt-1 size-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

        <div className="grow">
          <h3 className="md:text-lg font-semibold text-gray-800">
            What are Journeys?
          </h3>
          <p className="mt-1 text-gray-500">
            Each Journey contains a variety of interviews for that particular topic. Each interview offers a unique exprience which will yield unique personal content.
          </p>
        </div>
      </div>
    </div>

    <div className="py-8 first:pt-0 last:pb-0">
      <div className="flex gap-x-5">
        <svg className="shrink-0 mt-1 size-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

        <div className="grow">
          <h3 className="md:text-lg font-semibold text-gray-800">
            What do I get on the Free tier?
          </h3>
          <p className="mt-1 text-gray-500">
            You get access to all of our FREE Journeys and our content studio so that you can make edits as needed.
          </p>
        </div>
      </div>
    </div>

    <div className="py-8 first:pt-0 last:pb-0">
      <div className="flex gap-x-5">
        <svg className="shrink-0 mt-1 size-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

        <div className="grow">
          <h3 className="md:text-lg font-semibold text-gray-800">
            What type of content can I generate?
          </h3>

          <p className="mt-1 text-gray-500">
            Currently you can generate content tailored to LinkedIn posts, short form X {'(Twitter)'} posts, blog articles and newsletter copy. More coming soon.
          </p>
        </div>
      </div>
    </div>

    <div className="py-8 first:pt-0 last:pb-0">
      <div className="flex gap-x-5">
        <svg className="shrink-0 mt-1 size-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

        <div className="grow">
          <h3 className="md:text-lg font-semibold text-gray-800">
            How long are the interviews?
          </h3>
          <p className="mt-1 text-gray-500">
            The length of the interviews depend on your answers and how you communicate with our AI assistant. They can range from 15-minutes to 30-minutes on average.
          </p>
        </div>
      </div>
    </div>

    <div className="py-8 first:pt-0 last:pb-0">
      <div className="flex gap-x-5">
        <svg className="shrink-0 mt-1 size-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

        <div className="grow">
          <h3 className="md:text-lg font-semibold text-gray-800">
            How long does it take to generate content?
          </h3>
          <p className="mt-1 text-gray-500">
            Once your interview is over you can expect to start generating content in under 5-minutes.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
)
}

export default FAQ;