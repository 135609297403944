import './Footer.css';
import logo from '../../assets/design/yi_logo.png';
import logo_white from '../../assets/design/logo_white.png';
import ios from '../../assets/design/ios.png';
import googleplay from '../../assets/design/googleplay.png';
import { Link } from 'react-router-dom';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <>
      <div className='footer'>
        <div className='footer__links'>
          <div className='footer__links-column'>
            <div className='footer__logo'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" height="40">
  <polyline 
    points="
      40,50 
      50,30 60,70 
      70,20 80,80 
      90,35 100,65 
      110,45 120,55 
      130,48 140,52 
      150,49 160,51"
    fill="none"
    stroke="#2563eb "
    strokeWidth="6"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  
  <circle cx="170" cy="50" r="3" fill="#FF3366"/>
</svg> <span style={{fontWeight: 'bold', fontSize: '22px'}}>YourInterviewer.</span>
            </div>
          </div>

          <div className='footer__links-column'>
            <h3>Content</h3>

            <div>
              <a href='/blog'>Blog</a>
              <a href='/pricing'>Pricing</a>
            </div>
          </div>

          <div className='footer__links-column'>
            <h3>Support</h3>

            <div>
              <a href='/contact'>Contact</a>
            </div>
          </div>



          <div className='footer__links-column'>
            <h3>Documentation</h3>

            <div>
              <a href='/terms'>Terms</a>
              <a href='/privacy'>Privacy Policy</a>
              <a href='/policies'>Policies</a>
            </div>
          </div>
        </div>

        <div className='footer__copyright center'>
          &copy; {year} {process.env.REACT_APP_SITE_DOMAIN} - All Rights Reserved
        </div>
      </div>
    </>
  );
}

export default Footer;
