import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import congrats from '../../assets/design/dashboard/congrats.png';
import { getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, getDemoQualifierQuestions, answerQualifierQuestion } from '../../utils/api';
import imgScreenshot from '../../assets/components/contentstudio_screenshot.png';
function SectionLeftImage() {


return (
  
<div class="overflow-hidden bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
    <img src={imgScreenshot} alt="Product screenshot" class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" style={{width: '100%'}} />

      <div class="lg:pr-8 lg:pt-4">
        <div class="lg:max-w-lg">
          <h2 class="text-base/7 font-semibold text-indigo-600">Crafting</h2>
          <p class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Craft the perfect piece of content</p>
          <p class="mt-6 text-lg/8 text-gray-600">Finetune your content to match your exact needs.</p>
          <dl class="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
              <svg class="absolute left-1 top-1 size-5 text-indigo-600" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
</svg>

                Edit and Make Revisions in Content Studio.
              </dt>
              
              <dd class="inline ml-1">Fine-tune your interview outputs with our intuitive Content Studio. Make edits, revise drafts, and polish your narrative effortlessly.</dd>
            </div>

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute left-1 top-1 size-5 text-indigo-600">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>


                Retake interviews for even more variety.
              </dt>
              <dd class="inline ml-1">Retake interviews and craft entirely new content based on your updated responses. Best of all, you don't lose your previous drafts.</dd>
            </div>

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
                <svg class="absolute left-1 top-1 size-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                  <path d="M4.632 3.533A2 2 0 0 1 6.577 2h6.846a2 2 0 0 1 1.945 1.533l1.976 8.234A3.489 3.489 0 0 0 16 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234Z" />
                  <path fill-rule="evenodd" d="M4 13a2 2 0 1 0 0 4h12a2 2 0 1 0 0-4H4Zm11.24 2a.75.75 0 0 1 .75-.75H16a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75h-.01a.75.75 0 0 1-.75-.75V15Zm-2.25-.75a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75h-.01Z" clip-rule="evenodd" />
                </svg>
                Streamlined Draft Revisions.
              </dt>
              <dd class="inline ml-1">Revisit and refine your work in the Content Studio with tools designed to simplify the revision process. From small adjustments to significant overhauls, perfect your content without breaking a sweat.</dd>
            </div>
          </dl>
        </div>
      </div>

    </div>
  </div>
</div>
)
}

export default SectionLeftImage;