import {useState,useEffect} from 'react';
import './DemoConfirmation.css';
import './JourneyStep.css';
import { useLocation } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, testGenMedia, addUserPreference, removeUserPreference, getJourneys, checkSubscriptionStatus } from '../../utils/api';
import { FaBrain, FaBriefcase, FaCalendarAlt, FaCaretLeft, FaCloud, FaLock, FaRegCalendarCheck, FaRegLightbulb, FaRegStar, FaSoundcloud, FaSpinner, FaStar, FaTimes, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FirstAssistant from './FirstAssistant';
import FadeIn from '../../utils/fadein';
import TypeWriter from './components/TypeWriter';
import imgLogo from '../../assets/design/yi_logo.png';

function JourneyStep() {
  const [referenceId, setReferenceId] = useState('');
  const [journeys, setJourneys] = useState([]);
  const [loadedJourneys, setLoadedJourneys] = useState(false);
  const [steps, setSteps] = useState([]);
  const [demo, setDemo] = useState({});
  const [onboardingStatus, setOnboardingStatus] = useState(false);
  const [loadedOnboarding, setLoadedOnboarding] = useState(false);
  const [preferredName, setPreferredName] = useState('');
  const search = useLocation().search;
  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showOnboarding1, setShowOnboarding1] = useState(false);
  const [showOnboarding2, setShowOnboarding2] = useState(false);
  const [showOnboarding3, setShowOnboarding3] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentJourneyId, setCurrentJourneyId] = useState(null);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [loadedSubscriptionCheck, setLoadedSubscriptionCheck] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState(0);

  useEffect(() => {
    async function loadSubscriptionCheck(){
      let res = await checkSubscriptionStatus();

      if (res.success){
        setHasSubscription(res.data);
        setLoadedSubscriptionCheck(true);
      }

      else{
        setHasSubscription(false);
        setLoadedSubscriptionCheck(true);
      }
    }
    async function loadJourneys(){
      let res = await getJourneys();
      
      if (res.success){
        setJourneys(res.data);
        setLoadedJourneys(true);
      }
    }

    async function checkOnboarding(){
      let res = await getOnboardingStatus();

      if (res.success){
        setOnboardingStatus(res.data);
        setLoadedOnboarding(true);
      }
    }

    async function loadSteps(){
      let res = await getJourneySteps(1);
      if (res.success){
        setSteps(res.data);
        setLoaded(true);
      }
    }

    loadSubscriptionCheck();
    checkOnboarding();
    loadJourneys();
   // checkToken();
    loadSteps();
  }, []);

  function handleFeedbackClick(i){
    setFeedbackValue(i);
  }

  function renderStars(){
    let arr = [];
    let _value = feedbackValue;

    for (let i = 0; i < 5; i++){
      if (i <= _value){
        arr.push(<span onClick={() => handleFeedbackClick(i) }><FaStar /></span>);
      }

      else{
        arr.push(<span onClick={() => handleFeedbackClick(i) }><FaRegStar /></span>);
      }
    }

    return (
      <div className='dashboarddemo__star'>
        {arr}
      </div>
    )
  }

  async function loadSteps(){
    let res = await getJourneySteps(currentJourneyId);
    if (res.success){
      setSteps(res.data);
      setLoaded(true);
    }
  }

  function loadOnboarding(){
    const timer = setTimeout(() => {
      setShowOnboarding(true);
      setShowOnboarding1(true);
    }, 1000);
    return () => clearTimeout(timer);
  }

  async function handleNextStep(step){
    if (step == 1){
      setCurrentStep(step);
      setShowOnboarding1(false);
      setShowOnboarding2(true);
    }

    else if (step == 2){
      let res = await updateUser({preferred_name: preferredName});

      if (res.success){
        setShowOnboarding2(false);
        setShowOnboarding3(true);
      }
    }

    else if (step == 3){
      let res = await updateUser({onboarding_complete: true});

      if (res.success){
        setShowOnboarding2(false);
        setShowOnboarding3(false);
        setShowOnboarding(false);
      }
    }
  }

  useEffect(() => {
    if (loadedOnboarding == true && onboardingStatus == false)
    {
      loadOnboarding();
    }
  }, [loadedOnboarding]);

  async function handleShowChat(id, item){
    setSelectedStepId(id);
    setSelectedStep(item);
    setShowChat(true);
  }

  async function handleShowUnlock(id, item){
    setShowUnlockModal(true);
    setSelectedStepId(id);
    setSelectedStep(item);
  }

  async function handleTest(){
    let res = await testGenMedia();

    alert('done');
  }

  async function handlePreference(name){
    let element = document.getElementById(`pref_${name}`);
    element.classList.toggle('selected');

    if (element.classList.contains('selected')){
      let res = await addUserPreference(name);
    }

    else{
      let res = await removeUserPreference(name);
    }
  }

  async function handleJourneyClick(id, item){

    setCurrentJourneyId(id);
    setSelectedJourney(item);
  }

  useEffect(() => {
    if (currentJourneyId != null){
      setSteps([]);
      loadSteps();
    }
  }, [currentJourneyId]);

return (
  <div>
        {loadedJourneys == false && currentJourneyId == null && (
          <div>
            <Spinner />
          </div>
        )}

    {loadedJourneys == true && currentJourneyId == null && (
      <FadeIn delay={300} duration={300}>
        <div className='dashboard__top-header mt-20'>
          <h1>Available Journeys</h1>
        </div>

        <p className='mt-20'>
          Choose a journey to begin crafting content.
        </p>

        <div className='dashboard__journey-items mt-10'>
          {journeys.map((item, index) => (
            <div className='dashboard__journey-item' style={{
              backgroundImage: `url("${item.image_url}")`,
              backgroundSize: 'cover'
            }} onClick={() => handleJourneyClick(item.journey_id, item)}>
              <div className='dashboard__journey-item-body'>
                <div className='dashboard__journey-item-name'>
                  {index + 1}. {item.name}
                </div>

                <div className='dashboard__journey-item-btn'>
                  <button className='btn'>{item.completed_steps} / {item.step_count} Interviews</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </FadeIn>
    )}

    {currentJourneyId != null && (
      <>
      <div className='mt-10'>
        <span className='link' onClick={() => {
          setCurrentJourneyId(null);
          setSelectedJourney(null);
          setSteps([])
        }}><FaCaretLeft /> Back to Journeys</span>
      </div>

        <div className='dashboard__top-header mt-20'>
          <h1>{selectedJourney.name}</h1>
        </div>

        <p className='mt-10'>
          <i>Start your interview journey.</i>
        </p>

        {loaded == false && (
          <div className='center'>
            <FaSpinner />
          </div>
        )}

        <div className='dashboard__journeys mt-10'>
          {steps != null && steps.length > 0 && (
            <>
              {steps.map((item, index) => (
                <div key={index} className='dashboard__journey' style={{
                  backgroundImage: `url("${item.image_url}")`,
                  backgroundSize: 'cover',
                }}>
        
                <div className='dashboard__journey-title'>
                  <h2>{index + 1}. {item.name}</h2>
        
                  <div className='dashboard__journey-description'>
                    {item.description}
                  </div>

                  {/* <div className='dashboard__journey-description'>
                    Interview length: {item.min_duration}-{item.max_duration} mins
                  </div> */}
                </div>
        
                <div className='dashboard__journey-cta'>
                  {item.is_completed != true && item.is_free == true && (
                  <button className='btn dashboard__journey-btn' onClick={() => handleShowChat(item.journey_step_id, item)}>
                    Get Started
                  </button>
                  )}

                {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == false && (
                  <button className='btn dashboard__journey-btn' onClick={() => handleShowUnlock(item.journey_step_id, item)}>
                    <FaLock /> Unlock
                  </button>
                  )}

                {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == true && (
                  <button className='btn dashboard__journey-btn' onClick={() => handleShowChat(item.journey_step_id, item)}>
                    Get Started
                  </button>
                  )}

                  {item.is_completed == true && (
                    <span className='dashboard__journey-completed-flag' onClick={() => handleShowChat(item.journey_step_id, item)}>
                      Completed
                    </span>
                  )}
                </div>
              </div>
              ))}
            </>
          )}
        </div>
        </>
    )}

  <div>
    {showChat && (
      <FadeIn delay={300} duration={300}>
      <div>
        <FirstAssistant showChat={showChat} setShowChat={setShowChat} journey_step_id={selectedStepId} selectedStep={selectedStep} loadSteps={loadSteps} />
      </div>
      </FadeIn>
    )}
    
    {showOnboarding && (
      <FadeIn delay={300} duration={300}>
        <div className='overlay'></div>
        <div className='dashboard__onboarding'>
          <div style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: '50000'
          }}>
            <img src='https://ik.imagekit.io/h30rx6jhj/design/12113932-6950-4485-813f-3c7fc66b3a77.png?updatedAt=1732490562196' style={{
              height: '60px'
            }} alt='' />
          </div>

          <div>
            <h2></h2>
          </div>

          {showOnboarding1 && (
            <>
          <h2>
            <div>
              <TypeWriter text="Hello! Let's setup your new interviewer!" speed={60} />
            </div>
          </h2>


          <p className='mt-30'>
            <button className='btn' onClick={() => handleNextStep(1)}>Next</button>
          </p>
        </>
          )}

{showOnboarding2 && (
      <FadeIn delay={300} duration={300}>
        <div className='overlay'></div>
        <div className='dashboard__onboarding'>
        <h2>
            <div>
              <TypeWriter text="What would you like your interviewer to call you?" speed={60} />
            </div>
          </h2>

          <p style={{
            width: '50%',
            margin: '20px auto'
          }} className='mt-30'>
            <input type='text' style={{
              width: '400px'
            }} className='input form-control' placeholder='Name' value={preferredName} onChange={(e) => setPreferredName(e.target.value)} />
          </p>

          <p >
            <button className='btn' onClick={() => handleNextStep(2)}>Next</button>
          </p>
        </div>
      </FadeIn>
    )}

{showOnboarding3 && (
      <FadeIn delay={300} duration={300}>
        <div className='overlay'></div>
        <div className='dashboard__onboarding'>
        <h2>
            <div>
              <TypeWriter text="Select the tone that you would like your interviewer to have" speed={60} />
            </div>
          </h2>

          <div className='dashboard__onboarding-tone-items'>
            <div id='pref_Friendly' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Friendly')}>
              Friendly
            </div>

            <div id='pref_Personal' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Personal')}>
              Personal
            </div>

            <div id='pref_Political' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Political')}>
              Political
            </div>

            <div id='pref_Edgy' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Edgy')}>
              Edgy
            </div>

            <div id='pref_Controversial' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Controversial')}>
              Controversial
            </div>
          </div>

          <p>
            <button className='btn' onClick={() => handleNextStep(3)}>Next</button>
          </p>
        </div>
      </FadeIn>
    )}
        </div>
      </FadeIn>
    )}


  </div>

  {showUnlockModal && (
    <>
      <div className='overlay'></div>

      <div className='modal dashboard__modal-unlock'>
        <div>
          <img src={selectedStep.image_url} alt='' />
        </div>

        <h2 className='modal__h2 center'>
          Upgrade your account to continue your journey
        </h2>

        <div className='mt-10 center btns'>
          <Link to='/upgrade' className='btn btn--primary' style={{backgroundColor: 'goldenrod'}}>
            GO PRO
          </Link>

          <div className='mt-30'>
            <span className='link link--skip link--cancel' onClick={()=> setShowUnlockModal(false)}>No Thanks</span>
          </div>
        </div>
      </div>
    </>
  )}
  </div>
)}

export default JourneyStep;