import './ProfileInfo.css';
import {useEffect, useState} from 'react';
import PersonaTypes from '../../components/PersonaTypes';
import FadeIn from '../../utils/fadein';
import { getSeniorityLevels, getUserProfile, getDepartments, updateUser, updateUserProfile, updatePersonaType, addPhoneNumber, hRefreshToken, getCountries } from '../../utils/api';
import {getRole, refresh} from '../../services/auth.service';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react'
import iconEdit from '../../assets/icons/edit.png';
import {Helmet} from "react-helmet";
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import moment from 'moment-timezone';
import { FaAsterisk } from 'react-icons/fa';

const ProfileInfo = ({userType}) => {
  const [user, setUser] = useState({});
  const [showPersonaType, setShowPersonaType] = useState(true);
  const [showTimezone, setShowTimezone] = useState(true);
  const [seniority, setSeniority] = useState([]);
  const [selectedSeniority, setSelectedSeniority] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName]= useState('');
  const [newJobTitle, setNewJobTitle] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [newDepartment, setNewDepartment] = useState('');
  const [newSeniority, setNewSeniority] = useState(0);
  const [newTimezone, setNewTimezone] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [selectedPersonaType, setSelectedPersonaType] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const imageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [fileName, setFileName] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    async function loadSeniority(){
      let data = await getSeniorityLevels();
      setSeniority(data.data);
    }

    async function loadCountries(){
      let res = await getCountries();

      if (res.success){
        setCountries(res.data);
      }
    }

    async function loadTimezones(){
      const timezoneList = moment.tz.names();
      setTimezones(timezoneList);
    }

    // based on users current role as buyer or seller
    async function loadUserProfile(){
      let response = await getUserProfile('');

      if (response.success){
        let data = response.data;

        setUser(response.data);
        setNewFirstName(data.first_name);
        setNewLastName(data.last_name);
        setNewJobTitle(data.job_title);
        setSelectedSeniority(data.seniority);
        setSelectedTimezone(data.timezone);
        setNewSeniority(data.seniority_level_id);
        setNewDepartment(data.department);
        setNewPhoneNumber(data.phone_number);
        setNewTimezone(data.tz);
        setLinkedin(data.linkedin_url);
        setDepartmentId(data.department_id);
        setSelectedCountry(data.country_id);

        // let role = data.roles.filter(item => item.role_type_id === 1)[0];
        // setSelectedPersonaType({persona_type_id: role.persona_type_id, name: role.persona_type, icon_image: role.icon_image});
      }
    }
    
    async function loadDepartments() {
      let data = await getDepartments();
      if (data.data) {
        setDepartments(data.data);
      }
    }

    loadTimezones();
    loadSeniority();
    loadUserProfile();
    loadDepartments();
    loadCountries();

    document.title = "Profile Info";
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleSaveLegalName(){
    if (newFirstName == '' || newLastName == ''){
      alert("Must provide a valid legan name");
      return;
    }

    let response = await  updateUser({first_name: newFirstName, last_name: newLastName});
    if (response.success){
      setUser(prevState => ({
        ...prevState, first_name: newFirstName, last_name: newLastName}));

        loadSaveBanner();
      //setShowLegalName(false);
      await hRefreshToken();
      //window.location.reload(false);
    }

    else if (response.status === 403){
      await refresh();

      let response = await  updateUser({first_name: newFirstName, last_name: newLastName});
      if (response.success){
        setUser(prevState => ({
          ...prevState, first_name: newFirstName, last_name: newLastName}));

          loadSaveBanner();
        await hRefreshToken();
      }
    }
  }

  async function handleSaveTimezone(){
    let response = await  updateUser({timezone: newTimezone});
    if (response.success){
      setUser(prevState => ({
        ...prevState, timezone: newTimezone}));

      setShowTimezone(false);
      loadSaveBanner();
    }

    else if (response.status === 403){
      await refresh();

      let response = await  updateUser({timezone: newTimezone});
      if (response.success){
        setUser(prevState => ({
          ...prevState, timezone: newTimezone}));

        setShowTimezone(false);
        loadSaveBanner();
      }
    }
  }

  async function handleSavePersonaType(){
      let res = await updatePersonaType(selectedPersonaType.persona_type_id, getRole());
      loadSaveBanner();
  }

  async function handleSaveJobTitle(){
    if (newJobTitle == ''){
      alert("Please provide a valid job title");
      return;
    }

    let response = await updateUserProfile({job_title: newJobTitle});
    if (response.success){
      setUser(prevState => ({
        ...prevState, job_title: newJobTitle}));

        loadSaveBanner();
    }

    else if (response.status === 403){
      await refresh();

      let response = await updateUserProfile({job_title: newJobTitle});
      if (response.success){
        setUser(prevState => ({
          ...prevState, job_title: newJobTitle}));

          loadSaveBanner();
      }
    }
  }

  async function handleSaveDepartment(){
    if (departmentId === null){
      alert("Please select a valid department");
      return;
    }
    
    let response = await updateUserProfile({department: newDepartment, department_id: departmentId});
    
    if (response.success){
      setUser(prevState => ({...prevState, department: newDepartment, department_id: departmentId}));
      loadSaveBanner();
    }

    else if (response.status === 403){
      await refresh();

      let response = await updateUserProfile({department: newDepartment, department_id: departmentId});
    
      if (response.success){
        setUser(prevState => ({...prevState, department: newDepartment, department_id: departmentId}));
        loadSaveBanner();
      }
    }
  }

  async function handleSaveSeniority(){
    let response = await updateUserProfile({seniority_level_id: newSeniority});
    if (response.success){

      let tmp = seniority.filter(item => item.seniority_level_id === parseInt(newSeniority));

      setUser(prevState => ({
        ...prevState, seniority_level_id: newSeniority}));

      if (tmp.length > 0)
        setSelectedSeniority(tmp[0].name);

        loadSaveBanner();
    }
  }

  async function handleSaveCountry(){
    let response = await  updateUser({country_id: selectedCountry});
    if (response.success){
      setUser(prevState => ({
        ...prevState, country_id: selectedCountry}));
      loadSaveBanner();
    }

    else if (response.status === 403){
      await refresh();

      let response = await  updateUser({country_id: selectedCountry});
      if (response.success){
        setUser(prevState => ({
          ...prevState, country_id: selectedCountry}));
        loadSaveBanner();
      }
    }
  }

  async function handleSavePhoneNumber(){
    if (newPhoneNumber && newPhoneNumber.length > 0){
    let response = await addPhoneNumber(newPhoneNumber);
    if (response.success){
      loadSaveBanner();
      //setShowPhoneNumber(false);
    }
  }

  else{
    alert("Must enter a valid number");
  }
  }

  async function handleDepartmentChange(e){
    setSelectedDepartment(e.target.value);
    setDepartmentId(e.target.value);
  }

  async function handleSaveLinkedin(){
    let response = await updateUserProfile({linkedin_url: linkedin});
    if (response.success){
        loadSaveBanner();
    }

    else if (response.status === 403){
      await refresh();

      let response = await updateUserProfile({linkedin_url: linkedin});
      if (response.success){
          loadSaveBanner();
      }
    }
  }

  async function onError(){

  }

  function onSuccess(e){
    setFileName(e.name);
  }

  useEffect(() => {
    // file has been changed
    setUser(prevState => ({...prevState, profile_image: fileName}));
    handleSaveLogo();
    
  }, [fileName]);

  async function handleSaveLogo(){

    if (fileName.length > 0){
      let res = await updateUserProfile({profile_image: fileName});

      if (res.success){
        loadSaveBanner();
      }

      else if (res.status === 403){
        await refresh();
        let res = await updateUserProfile({profile_image: fileName});

      if (res.success){
        loadSaveBanner();
      }
      }
    }
  }

  return (
    <div className="profileinfo">
      <Helmet>
          <meta name="description" content="" />
      </Helmet>

      <div><Link className='btn' to="/account/home">Back</Link></div>
      <div className="account__header">Profile Info</div>

      <p style={{margin: '15px 0px'}}>
        Keep your profile up to date with your latest employers information.
      </p>

      <div className='profileinfo__form'>
        <div style={{textAlign: 'right', fontSize: '12px', fontStyle: 'italic', marginBottom: '10px', color: 'tomato'}}><FaAsterisk /> Required fields</div>
        <div className='profileinfo__form-group'>
        <div className="profileinfo__form-group-title">Profile Picture</div>

        <div className="companyedit__logo">
              {user.profile_image && user.profile_image !== '' && (
                <div className="companyedit__logo-image">
                  <img src={imageUrl + 'profileimages/' + user.profile_image} alt="logo found" />
                </div>
              )}

              {(user.profile_image == null || user.profile_image === '') && (
                <div className="companyedit__logo-image">
                  <img src={iconCompanyDefault} alt="logo not found" />
                </div>
              )}

              <div style={{display: "flex"}}>
                <IKContext publicKey="public_fgiAqZRw7zQyLP52QaVE34fRbH4=" urlEndpoint="https://ik.imagekit.io/h30rx6jhj" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}
                authenticator={()=>Promise} >
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/profileimages"}
                  onError={onError} onSuccess={onSuccess}
                />
                </IKContext>
              </div>
            </div>
        </div>

        <div className="profileinfo__form-group">
          <div className="profileinfo__form-group-title">Legal Name</div>

          <div className="">
              <div className="">
                <label className="label">First name <span style={{fontSize: '10px', color: 'tomato'}}><FaAsterisk /></span></label>
                <input type="text" id="txtFirstName" className="input" value={newFirstName} onChange={event => { setNewFirstName(event.target.value); }} placeholder='Enter first name' />
              </div>

              <div className="" style={{marginTop: "20px"}}>
                <label className="label">Last name <span style={{fontSize: '10px', color: 'tomato'}}><FaAsterisk /></span></label>
                <input type="text" id="txtLastName" className="input" value={newLastName} onChange={event => { setNewLastName(event.target.value); }} placeholder='Enter last name' />
              </div>

              <div className="profileinfo__btn">
                <button className="btn btn--primary" onClick={handleSaveLegalName}>Save</button>
              </div>
            </div>
        </div>

        {/* <div className="profileinfo__form-group">
          <div>
            <div className="profileinfo__form-group-title">Persona Type</div>

            {!showPersonaType && (
              <div className="profileinfo__form-group-current">{selectedPersonaType.name}</div>
            )}
          </div>

          <div className="">
            <PersonaTypes selectedPersonaType={selectedPersonaType} setSelectedPersonaType={setSelectedPersonaType} />

            <div className="profileinfo__btn">
                <button className="btn btn--primary" onClick={handleSavePersonaType}>Save</button>
            </div>
          </div>
        </div> */}

        <div className="profileinfo__form-group">
          <div className="profileinfo__form-group-title">Job Title <span style={{fontSize: '10px', color: 'tomato'}}><FaAsterisk /></span></div>

          <div className="profileinfo__jobtitle">
             <div>
                <input type="text" id="txtJobTitle" className="input" value={newJobTitle} onChange={event => { setNewJobTitle(event.target.value); }} placeholder='Enter your job title' />
              </div>

              <div className='profileinfo__btn'>
                <button className="btn btn--primary" onClick={handleSaveJobTitle}>Save</button>
              </div>
          </div>
        </div>

        {/* <div className="profileinfo__form-group">
          <div className="profileinfo__form-group-title">Seniority Level</div>

          <div className="profileinfo__edit">
             <div>
                <select className="input"
                    name='seniority'
                    value={newSeniority}
                    required
                    onChange={(e) => setNewSeniority(e.target.value)}
                >
                    <option>Select seniority</option>
                    {seniority.length > 0 && (
                        seniority.map((item, index) => (
                            <option style={{fontFamily: "var(--secondary-font)"}} key={index} value={item.seniority_level_id}>
                                {item.name}
                            </option>
                        ))
                    )}
                </select>
              </div>

              <div className="profileinfo__btn">
                <button className="btn btn--primary" onClick={handleSaveSeniority}>Save</button>
              </div>
          </div>
        </div> */}

        {/* <div className="profileinfo__form-group">
          <div className="accountinfo__form-group-title">Department</div>

          <div>
              <select id='selectDepartment' className='input' onChange={(e) => handleDepartmentChange(e)}>
                  <option>Select your department...</option>
                  {departments.map((item, index) => (
                    <>
                    {departmentId === item.department_id && (
                      <option key={index} value={item.department_id} selected>{item.name}</option>
                    )}

                    {departmentId !== item.department_id && (
                      <option key={index} value={item.department_id}>{item.name}</option>
                    )}
                    </>
                  ))}
              </select>

              <div className='profileinfo__btn'>
                <button className="btn btn--primary" onClick={handleSaveDepartment}>Save</button>
              </div>
            </div>
        </div> */}

        {/* <div className="profileinfo__form-group">
          <div className="profileinfo__form-group-title">LinkedIn Profile URL</div>

          <div className="profileinfo__edit">
           <div>
                <input type="text" id="txtLinkedin" className="input" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} placeholder="LinkedIn Profile URL" />
              </div>

              <div className='profileinfo__btn'>
                <button className="btn btn--primary" onClick={handleSaveLinkedin}>Save</button>
              </div>
          </div>
        </div> */}

        {/* <div className="profileinfo__form-group" id="phone">
          <div className="profileinfo__form-group-title">Phone Number</div>

          <div className="profileinfo__edit">
           <div>
                <input type="text" id="txtPhoneNumber" className="input" value={newPhoneNumber} onChange={(e) => setNewPhoneNumber(e.target.value)} placeholder="Enter your phone number" />
              </div>

              <div className='profileinfo__btn'>
                <button className="btn btn--primary" onClick={handleSavePhoneNumber}>Save</button>
              </div>
          </div>
        </div> */}

        {/* <div className='profileinfo__form-group'>
          <div className="profileinfo__form-group-title">Country</div>

          <div>
            <select className='input' id='selectCountry' value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
              <option>Select country</option>

              {countries.map(item => (
                <option value={item.country_id}>{item.display_name}</option>
              ))}
            </select>
          </div>

          <div className='profileinfo__btn'>
                <button className="btn btn--primary" onClick={handleSaveCountry}>Save</button>
              </div>
        </div> */}

        <div className="profileinfo__form-group">
          <div>
            <div className="profileinfo__form-group-title">Timezone <span style={{fontSize: '10px', color: 'tomato'}}><FaAsterisk /></span></div>

            {!showTimezone && (
            <div className="profileinfo__form-group-current">{newTimezone ? newTimezone : <span className="accountinfo__placeholder">Your timezone.</span>}</div>
            )}
          </div>

          <div className="profileinfo__edit">
           <div>

           <select className='select input' id="defaultTimeZoneId" value={newTimezone} name="defaultTimeZoneId" onChange={(e) => setNewTimezone(e.target.value)}>
              {timezones.map((timezone) => (
                <option key={timezone} value={timezone}>
                  {timezone}
                </option>
              ))}
            </select>
              </div>

              <div className='profileinfo__btn'>
                <button className="btn btn--primary" onClick={handleSaveTimezone}>Save</button>
              </div>
          </div>

          {/* <div className="profileinfo__edit-link" onClick={() => setShowTimezone(!showTimezone)}>
            {showTimezone ? <span>Cancel</span> : <span><img src={iconEdit} alt="" className="account__iconedit" /> Edit</span>}
          </div> */}
        </div>
      </div>

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
      <div className='profileinfo__saved-banner'>
        Profile updated successfully!
      </div>
      </FadeIn>
      )}
    </div>
  )
}

export default ProfileInfo;