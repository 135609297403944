import './Subscription.css';
import {useEffect, useState} from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { getUserCompany, getUserProfile, pauseCompany, updateEmail, checkSubscriptionStatus, cancelSubscription } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import {Helmet} from "react-helmet";
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import { FaTimes } from 'react-icons/fa';

const Subscription = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  async function loadStatus(){
    let res = await checkSubscriptionStatus();

    if (res.success){
      setStatus(res.data);
      console.log('data', res.data);
      setLoaded(true);
    }
  }

  useEffect(() => {
    async function loadStatus(){
      let res = await checkSubscriptionStatus();

      if (res.success){
        setStatus(res.data);
        console.log('data', res.data);
        setLoaded(true);
      }
    }
    async function loadCompany(){
      let result = await getUserProfile();

      if (result.success){
        setEmail(result.data.email);
      }

      else if (result.status === 403){
        await refresh();

        let result = await getUserProfile();

        if (result.success){
          setEmail(result.data.email);
        }
      }
    }

    loadStatus();
    loadCompany();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleEmailChange(){
    if (email.length > 0){
      let res = await updateEmail(email);

      if (res.success){
        loadSaveBanner();
        setShowMessage(true);
      }

      else if (res.status === 403){
        await refresh();
        let res = await updateEmail(email);

        if (res.success){
          loadSaveBanner();
          setShowMessage(true);
        }

        else{
          alert(res.message);
        }
      }

      else{
        alert(res.message);
      }
    }

    else{
      alert("Must include a valid email address");
    }
  }

  async function handleConfirmCancel(){
    setCancelling(true);

    let res= await cancelSubscription();

    if (res.success){
      setCancelling(false);
      setShowConfirm(false);
      loadStatus();
    }
  }

  return (
    <div className="subscription">
      <Helmet>
        <meta name="description" content="" />
      </Helmet>

      <div className=""><Link className='btn' to="/account/home">Back</Link></div>
      <div className="account__header">Subscriptions</div>

      {loaded == false && (
        <div>
          <Spinner />
        </div>
      )}

      {loaded == true && status == true && (
        <div className='subscription__status subscription__has-sub'>
            <h3>Subscription Status</h3>

            <p>
              You currently have an active <span className='highlight'>PRO</span> account.
            </p>

            <p>
              Need to cancel your subscription?
            </p>

            <p>
              <span className='link' onClick={() => setShowConfirm(true)}>Cancel Subscription</span>
            </p>
        </div>
      )}

      {loaded == true && status == false && (
        <div className='subscription__status subscription__no-sub'>
          <h3>Subscription Status</h3>

          <p>
            You're on the <strong>Basic</strong> plan.
          </p>

          <p>
            <Link className='link' to='/upgrade'>Upgrade to PRO</Link>
          </p>
        </div>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
        Profile updated successfully!
        </div>
      </FadeIn>
      )}

      {showConfirm && (
        <FadeIn delay={150} duration={300}>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowConfirm(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Cancel Your Subscription?
            </h2>

            <p className='mt-30'>
              {cancelling == false && (
              <button className='btn btn--primary' onClick={()=> handleConfirmCancel()}>Confirm Cancellation</button>
              )}
              
              {cancelling == true && (
                <Spinner />
              )}
            </p>
          </div>
      </FadeIn>
      )}
    </div>
  )
}

  export default Subscription;