import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getJourneyInterviews, getJourneys, getJourneyStep, updateJourneyStep, getOutputTypes, getOutputTypesForJourneyStep, addOutputTypesToJourneyStep, removeOutputTypeToJourneyStep } from '../../utils/api/admin';
import * as FaIcons from 'react-icons/fa';

function JourneyStepEdit({journey_step_id, setShowModal, loadData}) {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [assistantId, setAssistantId] = useState('');
  const [minDuration, setMinDuration] = useState('0');
  const [maxDuration, setMaxDuration] = useState('0');
  const [orderId, setOrderId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [journeyId, setJourneyId] = useState('');
  const [outputPrompt, setOutputPrompt] = useState('');
  const [structuredDataSchema, setStructuredDataSchema] = useState('');
  const [status, setStatus] = useState('Draft');
  const [isFree, setIsFree] = useState(false);
  const [outputTypes, setOutputTypes] = useState([]);
  const [journeys, setJourneys] = useState([]);
  const [selectedJourneyId, setSelectedJourneyId] = useState(null);
  const [seoUrl, setSeoUrl] = useState('');
 // const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0){
      return <p>No icon.</p>
    }
  
    // Dynamically get the icon component from the FaIcons object
    const IconComponent = FaIcons[iconName];
  
    // If the icon is found, render it
    if (IconComponent) {
      return <IconComponent />;
    }
  
    // Fallback if icon is not found
    return <p>Icon not found</p>;
  };

  async function loadOutputTypes(){
    let res = await getOutputTypesForJourneyStep(journey_step_id);

    if (res.success){
      setOutputTypes(res.data);
    }
  }

  useEffect(() => {
    async function loadJourneys(){
      let res = await getJourneys();

      if (res.success){
        setJourneys(res.data);
      }
    }

    loadJourneys();
  }, []);

  useEffect(() => {
    async function loadData(){
      let res = await getJourneyStep(journey_step_id);

      if (res.success){
        let data = res.data;
        setData(data);

        setJourneyId(data.journey_id);
        setOrderId(data.order_id);
        setName(data.name);
        setDescription(data.description);
        setAssistantId(data.assistant_id);
        setMinDuration(data.min_duration);
        setMaxDuration(data.max_duration);
        setImageUrl(data.image_url);
        setOutputPrompt(data.output_prompt);
        setStructuredDataSchema(data.structured_data_schema);
        setStatus(data.status);
        setIsFree(data.is_free);
        setSelectedJourneyId(data.journey_id);
        setSeoUrl(data.seo_url);
        setLoaded(true);
      }
    }

    async function loadOutputTypes(){
      let res = await getOutputTypesForJourneyStep(journey_step_id);

      if (res.success){
        setOutputTypes(res.data);
      }
    }

    loadData();
    loadOutputTypes();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleUpdate(){
    let res = await updateJourneyStep(journey_step_id, name, description, imageUrl, assistantId, minDuration, maxDuration, orderId, outputPrompt, structuredDataSchema, status, selectedJourneyId, seoUrl);

    if (res.success){
      alert("Updated");
      loadData();
      setShowModal(false);
    }
  }

  async function handleOutputTypeSelect(id, val){
    if (val == true){
      let res = await addOutputTypesToJourneyStep(journey_step_id, id);

      if (res.success){
        loadOutputTypes();
      }
    }

    else{
      let res = await removeOutputTypeToJourneyStep(journey_step_id, id);

      if (res.success){
        loadOutputTypes();
      }
    }
  }

  function handleGenerateFromTitle() {
    let temp = name
      .toLowerCase() // Convert to lowercase
      .trim() // Remove leading and trailing spaces
      .replace(/[^a-z0-9\s-]/g, '') // Remove non-alphanumeric characters (except spaces and hyphens)
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-'); // Remove consecutive hyphens

      temp += `-${journeyId}`;
      setSeoUrl(temp);
  }

  return (
    <>
      <Helmet>
        <title>Journeys</title>
      </Helmet>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <div>
            <div className='form-group'>
              <select className='input' value={selectedJourneyId} onChange={(e) => setSelectedJourneyId(e.target.value)}>
                <option>Select Journey</option>

                {journeys.map(item => (
                  <option value={item.journey_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group'>
              <label>Name</label>
              <input type='text' className='input' value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' />
            </div>

            <div className='form-group'>
              <label>Description</label>
              <textarea type='text' className='input' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description'></textarea>
            </div>

            <div className='form-group'>
              <label>Seo URL <span className='ml-2 link' onClick={() => handleGenerateFromTitle()}>Generate from title</span></label>
              <input type='text' className='input' value={seoUrl} onChange={(e) => setSeoUrl(e.target.value)} placeholder='Name' />
            </div>

            <div className='form-group'>
              <label>Order ID</label>
              <input type='text' className='input' value={orderId} onChange={(e)=> setOrderId(e.target.value)} placeholder='Order Id' />
            </div>

            <div className='form-group'>
              <label>Assistant ID (From Vapi)</label>
              <input type='text' className='input' value={assistantId} onChange={(e) => setAssistantId(e.target.value)} placeholder='Assistant ID' />
            </div>

            <div className='form-group'>
              <label>Min Duration (In minutes)</label>
              <input type='text' className='input' value={minDuration} onChange={(e) => setMinDuration(e.target.value)} placeholder='Min duration' />
            </div>

            <div className='form-group'>
              <label>Max duration (In minutes)</label>
              <input type='text' className='input' value={maxDuration} onChange={(e) => setMaxDuration(e.target.value)} placeholder='Max duration' />
            </div>

            <div className='form-group'>
              <div>
                <img style={{width: '100%'}} src={`${imageUrl}`} />
              </div>
            </div>

            <div className='form-group'>
              <label>Image URL</label>
              <input type='text' className='input' value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
            </div>

            <div>
              <h2>Output Types</h2>

              <div>
                {outputTypes.map(item => (
                  <div className='' style={{
                    backgroundColor: '#f3f3f3',
                    padding: '10px',
                    border: 'solid 1px #ccc',
                    margin: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold'
                  }}>
                    <span><input type='checkbox' checked={item.selected} onChange={(e) => handleOutputTypeSelect(item.output_type_id, e.target.checked)} /></span>
                    <span className='ml-10'><IconDisplay iconName={item.fa_icon} /></span>
                    <span className='ml-10'>{item.name}</span>

                  </div>
                ))}
              </div>
            </div>

            <div className='form-group'>
              <label>Output Prompt</label>
              <textarea type='text' className='input' value={outputPrompt} onChange={(e) => setOutputPrompt(e.target.value)} placeholder='Output prompt'></textarea>
            </div>

            <div className='form-group'>
              <label>Structured Data Schema</label>
              <textarea type='text' className='input' value={structuredDataSchema} onChange={(e) => setStructuredDataSchema(e.target.value)} placeholder='Structured data schema'></textarea>
            </div>

            <div className='form-group'>
              <label>Status</label>
              <select className='input select' value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value='Draft'>Draft</option>
                <option value='Published'>Published</option>
                <option value='Paused'>Paused</option>
              </select>
            </div>

            <div className='form-group'>
              <label>Is Free</label>
              <input type='checkbox' checked={isFree} onChange={(e) => setIsFree(e.target.checked)} />
            </div>

            <div className='form-group'>
              <button className='btn' onClick={() => handleUpdate()}>Update</button>
            </div>
          </div>
       </div>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default JourneyStepEdit;