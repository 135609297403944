import React, { useState, useEffect } from 'react';
import close from '../../assets/design/pricing/closing-x.png';
import calendar from '../../assets/design/dashboard/calendar.png';
import './JourneyInterviewContentDetails.css';
import { getAllContent } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import * as FaIcons from 'react-icons/fa';
import { useParams, useNavigate, Link } from 'react-router-dom';

const JourneyInterviewContentDetails = () => {
  //state variables
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  let {id} = useParams();
  const navigate = useNavigate();

    const IconDisplay = ({ iconName }) => {
      if (iconName == null || iconName.length == 0){
        return <p>No icon.</p>
      }
    
      // Dynamically get the icon component from the FaIcons object
      const IconComponent = FaIcons[iconName];
    
      // If the icon is found, render it
      if (IconComponent) {
        return <IconComponent />;
      }
    
      // Fallback if icon is not found
      return <p>Icon not found</p>;
    };

  useEffect(() => {
    async function loadData(){
      let res = await getAllContent();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  return (
    <>
      <div className='contentstudio'>
      <div>
        <Link className='' to='/content'>Back to My Content</Link>
      </div>
      <h1>Content Type</h1>

        {loaded == false && (
          <Spinner />
        )}

        {loaded == true && (
          <div>
            <ul className='min-w-full divide-y divide-gray-200 dark:divide-neutral-700'>
              {data.map(item => (
                <li 
                key={item.date_created} 
                className="cursor-pointer rounded-sm flex justify-between gap-x-6 px-5 py-5 bg-white hover:border-b-2 hover:border-gray-400 hover:pb-4 transition-all duration-200"
              >
              
              

                <div className="flex min-w-0 gap-x-4">
                  <IconDisplay iconName={item.output_type_icon} />

                  <div className="min-w-0 flex-auto">
                    <p className="text-sm/6 font-semibold text-gray-900">{item.journey_name}</p>
                    <p className="mt-1 truncate text-xs/5 text-gray-500">{item.step_name}</p>
                  </div>
                </div>

                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  {item.date_created ? (
                    <p className="mt-1 text-xs/5 text-gray-500">
                      Last seen <time dateTime={item.date_created}>{item.date_created}</time>
                    </p>
                  ) : (
                    <div className="mt-1 flex items-center gap-x-1.5">
                      <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                        <div className="size-1.5 rounded-full bg-emerald-500" />
                      </div>
                      <p className="text-xs/5 text-gray-500">Online</p>
                    </div>
                  )}
                </div>
              </li>
              ))}
            </ul>
          </div>
        )}
        
      </div>
    </>
  );
};

export default JourneyInterviewContentDetails;
