import React, {useState, useEffect} from 'react';

const Typewriter = ({ text, speed = 100 }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(timeout);
    }
  }, [index, text, speed]);

  useEffect(() => {
    setIndex(0);
    setDisplayedText('');
  }, [text]);

  return (
    <div>
      {displayedText}
      <span className="cursor" style={{ opacity: index < text.length ? 1 : 0 }}>
        |
      </span>
    </div>
  );
};

export default Typewriter;