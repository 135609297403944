import './Account.css';
import ProfileInfo from './ProfileInfo';
import { Route, Routes } from 'react-router-dom';
import AccountMenu from './AccountMenu';
import AccountWrapper from './AccountWrapper';
import CompanyInfo from './CompanyInfo';
import CompanyEdit from './CompanyEdit';
import Calendar from './Calendar';
import WorkHours from './WorkHours';
import Software from './Software';
import SelectICP from './SelectICP';
import Notifications from './Notifications';
import PayoutInfo from './PayoutInfo';
import PayoutMethod from './PayoutMethod';
import PayoutMethodAdd from './PayoutMethodAdd';
import PayoutMethodGiftCard from './PayoutMethodGiftCard';
import UpdatePassword from './UpdatePassword';
import Invitations from './Invitations';
import Video from './Video';
import Team from './Team';
import Payouts from './Payouts';
import QualifierQuestions from './QualifierQuestions';
import PaymentMethod from './PaymentMethod';
import Settings from './Settings';
import Charges from './Charges';
import CompanyEmail from './CompanyEmail';
import SellerRefer from './SellerRefer';
import Addons from './Addons';
import OrderHistory from './OrderHistory';
import ScrollToTop from '../../components/layout/ScrollToTop';
import AccountHome from './AccountHome';
import BuyerDemosCompleted from '../buyerdemoscompleted/BuyerDemosCompleted';
import BuyerDemosUpcoming from '../buyerdemosupcoming/BuyerDemosUpcoming';
import BuyerDemosRescheduled from '../buyerdemosrescheduled/BuyerDemosRescheduled';
import SellerDemosUpcoming from '../sellerdemosupcoming/SellerDemosUpcoming';
import SellerDemosCompleted from '../sellerdemoscompleted/SellerDemosCompleted';
import SellerDemosRescheduled from '../sellerdemosrescheduled/SellerDemosRescheduled';
import SellerDemosAvailable from '../sellerdemosavailable/SellerDemosAvailable';
import BecomeSeller from '../becomeseller/BecomeSeller';
import BecomeBuyer from '../becomebuyer/BecomeBuyer';
import AdminDashboard from '../admin/admindashboard';
import ConfigureSettings from '../dashboard/ConfigureSettings';
import ContentStudio from '../dashboard/ContentStudio';
import ContentStudioItem from '../dashboard/ContentStudioItem';
import Subscription from './Subscription';

const Account = ({userType}) => {
  return (
    <>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<AccountWrapper view="settings" />}>
        <Route exact={true} path="/home" element={<AccountMenu />} />
        <Route exact={true} path="/profile" element={<ProfileInfo />} />
        <Route exact={true} path="/company" element={<CompanyInfo />}/>
        <Route exact={true} path="/company/edit" element={<CompanyEdit />} />
        <Route exact={true} path="/calendar" element={<Calendar />} />
        <Route exact={true} path="/workhours" element={<WorkHours />} />
        <Route exact={true} path="/software" element={<Software />} />
        <Route exact={true} path="/notifications" element={<Notifications />} />
        <Route exact={true} path="/payout" element={<PayoutInfo />} />
        <Route exact={true} path="/payout/method" element={<PayoutMethod />} />
        <Route exact={true} path="/payout/method/add" element={<PayoutMethodAdd />} />
        <Route exact={true} path="/refer-a-friend" element={<Invitations />} />
        <Route exact={true} path="/video" element={<Video /> } />
        <Route exact={true} path="/update-password" element={<UpdatePassword />} />
        <Route exact={true} path="/payout/method/gift-card" element={<PayoutMethodGiftCard />} />
        {/* <Route exact={true} path="/payouts" element={<Payouts view="payouts" />} /> */}
        <Route exact={true} path="/payment-method" element={<PaymentMethod />} />
        <Route exact={true} path="/settings" element={<Settings />} />
        <Route exact={true} path="/payment-history" element={<Charges />} />
        <Route exact={true} path="/work-email" element={<CompanyEmail />} />
        <Route exact={true} path="/refer" element={<SellerRefer />} />
        <Route exact={true} path="/order-history" element={<OrderHistory />} />
        <Route exact={true} path="/subscriptions" element={<Subscription /> } />
      </Route>

      <Route path="/" element={<AccountWrapper view="configure" />}>
        <Route exact={true} path='/configure' element={<ConfigureSettings />} />
      </Route>

      <Route path="/" element={<AccountWrapper view="studio" />}>
        <Route exact={true} path='/studio' element={<ContentStudio />} />
      </Route>

      <Route path="/" element={<AccountWrapper view="studio" />}>
        <Route exact={true} path='/studio/:id' element={<ContentStudioItem />} />
      </Route>

      <Route path="/" element={<AccountWrapper view="studio" />}>
        <Route exact={true} path='/studio/:id/:outputid' element={<ContentStudioItem />} />
      </Route>

      <Route path="/" element={<AccountWrapper view="team" />}>
        <Route exact={true} path="/team" element={<Team />} />
      </Route>

      <Route path="/" element={<AccountWrapper view="qualifiers" />}>
        <Route exact={true} path="/qualifiers" element={<QualifierQuestions />} />
      </Route>

      <Route path="/" element={<AccountWrapper view="icp" />}>
        <Route exact={true} path="/icp" element={<SelectICP />} />
      </Route>

      <Route path="/" element={<AccountWrapper view="addons" />}>
        <Route exact={true} path='/addons' element={<Addons />} />
      </Route>

      <Route exact={true} path="/admindashboard_X7T9F2G4J6H8K0L1Z3W5" element={<AdminDashboard />} />

      <Route exact={true} path='/workspace' element={<AccountHome />} />
      {/* <Route exact={true} path='/completed' element={<BuyerDemosCompleted />} />
      <Route exact={true} path='/s/completed' element={<SellerDemosCompleted />} />
      <Route exact={true} path='/upcoming' element={<BuyerDemosUpcoming />} />
      <Route exact={true} path='/s/upcoming' element={<SellerDemosUpcoming />} />
      <Route exact={true} path='/rescheduled' element={<BuyerDemosRescheduled />} />
      <Route exact={true} path='/s/rescheduled' element={<SellerDemosRescheduled />} />
      <Route exact={true} path='/s/available' element={<SellerDemosAvailable />} /> */}
      <Route exact={true} path='/become-a-seller' element={<BecomeSeller />} />
      <Route exact={true} path='/become-a-buyer' element={<BecomeBuyer />} />
      </Routes>
    </>
  )
}

export default Account;