import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaHeart, FaTimes } from 'react-icons/fa';
import './HomepageJourney.css';
import './Homepage.css';
import medialogos from '../../assets/design/logos/medialogos.png';
import bg from '../../assets/design/homepage/background1.png';
import HomepageBuyer from './HomepageBuyer';
import HomepageSeller from './HomepageSeller';
import imgLogo from './assets/logo.png';
import imgSocials from './assets/sociallogos.png';
import imgPeople from './assets/people.png';
import imgGirl1 from './assets/girl1.png';
import imgSide from './assets/leftside.png';
import imgSs1 from './assets/ss1.png';
import imgSs2 from './assets/ss2.png';
import imgSs3 from './assets/ss3.png';
import imgPost from './assets/post.png';
import imgSection0 from './assets/section0.png';
import imgHero from './assets/sectionhero.png';
import PreviewAssistant from './components/PreviewAssistant';
import { BuilderComponent, builder } from "@builder.io/react";
import Hero1 from '../../components/twcomponents/Hero1';
import Pricing from '../../components/twcomponents/Pricing';
import Pricing2 from '../../components/twcomponents/Pricing2';
import FAQ from '../../components/twcomponents/FAQ';
import Testimonials from '../../components/twcomponents/Testimonials';
import ContactUs from '../../components/twcomponents/ContactUs';
import BlogListing from '../../components/twcomponents/BlogListing';
import SectionRightImage from '../../components/twcomponents/SectionRightImage';
import SectionRightImageType from '../../components/twcomponents/SectionRightImageTypes';
import SectionLeftImage from '../../components/twcomponents/SectionLeftImage';
import SectionFeaturesIcons from '../../components/twcomponents/SectionFeaturesIcons';
import Hero2 from '../../components/twcomponents/Hero2';
import Hero3 from '../../components/twcomponents/Hero3';

function HomepageJourney() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [homepage, setHomepage] = useState(null);

  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  useEffect(() => {
    builder
      .get("homepage")
      .toPromise()
      .then((homepageData) => setHomepage(homepageData));
  }, []);

  return (
    <div className='homepagejourney'>
      <Helmet>
        <title>Craft unique content through 1-on-1 interviews</title>
      </Helmet>

      <div className='homepagejourney__hero' style={{display: 'none'}}>
         {/* fls jflafj
        <div>
          <Link to='/join-now'>
            <img src={imgHero} alt='' />
          </Link>
        </div> */}

        <div className='homepagejourney__hero-left'>
          <div className='homepagejourney__hero-left-copy'>
            WE <span className='highlight'>INTERVIEW</span> YOU
          </div>

          <div className='homepagejourney__hero-subtext'>
            To produce highly personalized content, optimized for engagement.
          </div>

          <div>
            <button onClick={() => setShowPreview(true)} className='homepagejourney__hero-get-started'>Try it out</button>
          </div>

          <div className='mt-10'>
            * No Signup Required
          </div>
        </div>

        <div className='homepagejourney__hero-right'>
          <div className='homepagejourney__steps'>
            <img src="https://ik.imagekit.io/h30rx6jhj/journeys/journey1/1.png?updatedAt=1732319575017" />
          </div>

          <div className='homepagejourney__steps'>
            <img src="https://ik.imagekit.io/h30rx6jhj/journeys/journey1/2.png?updatedAt=1732319575056" />
          </div>

          <div className='homepagejourney__steps'>
            <img src="https://ik.imagekit.io/h30rx6jhj/journeys/journey1/3.png?updatedAt=1732319575129" />
          </div>

          <div className='homepagejourney__steps'>
            <img src="https://ik.imagekit.io/h30rx6jhj/journeys/journey1/4_locked.png?updatedAt=1732319574809" />
          </div>

          <div className='homepagejourney__steps'>
            <img src="https://ik.imagekit.io/h30rx6jhj/journeys/journey1/5_locked.png?updatedAt=1732319574809" />
          </div>
        </div>
      </div>

          <Hero3 />

          {/* <Hero1 />

          <Hero2 /> */}

    {/* <SectionFeaturesIcons /> */}

    <SectionRightImageType />

<SectionRightImage />

<SectionLeftImage />


{/* Start Pricing */}

<Pricing2 />

{/* <Pricing /> */}

<FAQ />

{/* <Testimonials /> */}

{/* <ContactUs /> */}

{/* <BlogListing /> */}




      {/* {homepage && <BuilderComponent model="homepage" content={homepage} />} */}

      {/* <div className='homepagejourney__section0'>
        <div className='homepagejourney__section0-left'>
          <img src={imgPost} alt='' />
        </div>

        <div className='homepagejourney__section0-right'>
          <img src={imgSection0} alt='' />
        </div>
      </div> */}

      {/* <div className='homepagejourney__section1'>
        <div className='homepagejourney__section1-left'>
          <img src='https://ik.imagekit.io/h30rx6jhj/journeys/journey1/12113932-6950-4485-813f-3c7fc66b3a77.png?updatedAt=1732479353495' />
        </div>

        <div className='homepagejourney__section1-right'>
          <div className='homepagejourney__section1-right-title'>
            Need to create content but don't know where to start?
          </div>

          <p className='homepagejourney__section1-right-copy'>
            We ask you thought provoking questions to extract your personal stories and then turn them into content optimized for engagement.
          </p>
        </div>
      </div> */}

      {/* <div className='homepagejourney__section2'>
        <div className='homepagejourney__section2-left'>
          <img src='https://ik.imagekit.io/h30rx6jhj/design/Screenshot%202024-11-24%20123615.png?updatedAt=1732480636675' />
        </div>

        <div className='homepagejourney__section2-right'>
          <div className='homepagejourney__section2-right-title'>
            Interviewing is at the <FaHeart /> of storytelling
          </div>

          <div className='mt-20'>
            We turn your interviews into hyper personalized, high engaging content for multiple mediums.
          </div>

          <div className='mt-20'>
            <img src={imgSocials} alt='' style={{width: '60%'}} />
          </div>
        </div>
      </div> */}

      {/* <div className='homepage__section1'>
        <div className='homepage__section1-left'>
          <div className='homepage__section1-title'>
            <div>How Are We Different?</div>
            <div className='highlight'>No Direction Needed.</div>
          </div>

          <div className='homepage__section1-different'>
            <div>
              <h3>No Prompts</h3>

              <div className='homepage__section1-different-copy'>
                You know what you want. One-sided dialogue with AI prompt. Requirements: initiated by user, needs direction
              </div>
            </div>

            <div>
              <h3>No Chat AI</h3>

              <div className='homepage__section1-different-copy'>
                Back-and-forth dialogue to uncover new ideas and insights.  Requirements: initiated by user, needs direction
              </div>
            </div>
          </div>

          <div style={{padding: '20px'}}>
            <h3 className='highlight'>We Are Interview AI</h3>

            <p>
              In-depth back-and-forth dialogue that provokes critical thinking and descriptive details. Requirements: None. Initiated by AI, no direction needed
            </p>
          </div>

          <div style={{marginTop: '50px'}}>
            <span className='homepage__hero-btn'>
              Join Waitlist
            </span>
          </div>
        </div>

        <div className='homepage__section1-right'>
          <img src={imgGirl1} alt='' />
        </div>
      </div> */}

      {/* <div className='homepage__section2'>
        <h2 className='homepage__section1-title'>Still Skeptical? <span className='highlight'>Here's Some Examples.</span></h2>

        <div className='homepage__section2-screenshots'>
          <div className='homepage__section2-screenshot'>
            <img src={imgSs1} alt='' />
          </div>

          <div className='homepage__section2-screenshot'>
            <img src={imgSs2} alt='' />
          </div>

          <div className='homepage__section2-screenshot'>
            <img src={imgSs3} alt='' />
          </div>
        </div>
      </div> */}

      {/* <div className='homepage__section3'>
        <div className='homepage__section3-title'>
          <div>11x Better Than Any Other</div>
          <div className='highlight'>AI Content Creator</div>
        </div>

        <div className='homepage__section3-copy'>
          Don't wait! Get an edge over your competition by becoming a content machine today. Schedule 10-15 mins per week with your AI Interviewer to generate hundreds of pieces of content monthly!
        </div>

        <div className='center' style={{marginTop: '50px'}}>
          <span className='homepage__hero-btn'>
            Join Waitlist
          </span>
        </div>

        <div className='homepage__section3-copyright'>
        ©2024 LeadrPro, Inc. DBA Your Interviewer. All rights reserved. Privacy Policy
        </div>
      </div> */}

      {showPreview && (
      <FadeIn delay={300} duration={300}>
      <div>
        {/* <PreviewAssistant showChat={showPreview} setShowChat={setShowPreview} assistant_id={'2c6b8386-240c-436b-baf9-f5951aeb955a'} /> */}

        <PreviewAssistant showChat={showPreview} setShowChat={setShowPreview} assistant_id={'9c7a8aec-6c32-4628-825c-a31f5a3c5351'} />

        {/* <PreviewAssistant showChat={showPreview} setShowChat={setShowPreview} assistant_id={'b1cd9d74-b7a8-40ac-9799-f2256842b446'} /> */}
      </div>
      </FadeIn>
    )}
    </div>
  );
}

export default HomepageJourney;