import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getAdminDemosPending, getPageBreakdown, indexCompany } from '../../utils/api';
import { AdminCompanyWaitlist, getWaitlist } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaDownload } from 'react-icons/fa';

function Waitlist() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getWaitlist();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  const exportToCsv = () => {
    let _data = data.map(item => {
      return {name: item.name, email: item.email}
    });
    const headers = Object.keys(_data[0]).join(',') + '\n'; // Get CSV headers
    const rows = _data.map(row => Object.values(row).join(',')).join('\n'); // Get CSV rows
    const csvString = headers + rows;

    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const timestamp = new Date().toISOString().replace(/[:.-]/g, ''); // Generate a timestamp
    const filename = `data_${timestamp}.csv`;

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Waitlist {`(${data.length})`} <span onClick={() => exportToCsv()} className='cursor-pointer ml-4 px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'><FaDownload /> <span className='ml-10'>Export</span></span></h1>

      {loaded===false && (
        <Spinner />
      )}

{loaded && (
         <div>
  

          <table className='admindashboard__table dashboard__table table'>
            <tr>
              <th></th>
              <th>Date saved</th>
              <th>Name</th>
              <th>Email</th>
            </tr>

            {data.map((item, index) => (
              <tr>
                <td><strong>#{index}</strong></td>

                  <td>{new Date(item.date_created).toLocaleDateString()}</td>

                <td>

                  <div className='admindashboard__table-company-name'>{item.name}</div></td>
                <td>{item.email}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </>
  );
}

export default Waitlist;