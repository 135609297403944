import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaGift, FaTimes } from 'react-icons/fa';
import { getJourneyInterviews, getJourneySteps } from '../../utils/api/admin';
import JourneyStepEdit from './JourneyStepEdit';
import JourneyStepAdd from './JourneyStepAdd';

function JourneySteps() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getJourneySteps();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  async function loadData(){
    let res = await getJourneySteps();

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleEditClick(id){
    setSelectedId(id);
    setShowModal(true);
  }

  return (
    <>
      <Helmet>
        <title>Discover new software</title>
      </Helmet>

      <h1>Journey Interviews {`(${data.length})`}</h1>

      <div className='mt-20'>
        <span className='cursor-pointer px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800' onClick={(e) => setShowAdd(true)}>Add New Interview</span>
      </div>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th>Order ID</th>
              <th>Journey</th>
              <th>Interview Name</th>
              <th>Status</th>
              <th>Free</th>
              <th></th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td style={{width: '130px'}}>
                  <img src={`${item.image_url}`} style={{width: '200px'}} />
                </td>
                <td>{item.order_id}</td>
                <td>{item.journey_name}</td>
                <td style={{width: '200px'}}>{item.name}</td>

                <td>
                  {item.status == 'Published' && (
                    <span className='bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-green-100 dark:border-green-500'>Published</span>
                  )}

                  {item.status == 'Draft' && (
                    <span className='bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md border border-orange-100 dark:bg-gray-700 dark:border-orange-300 dark:text-orange-300'>Draft</span>
                  )}
                  </td>

                  <td>
                    {item.is_free == true && (
                      <span className='bg-red-200 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-red-100 dark:border-green-500'><FaGift /> Free</span>
                    )}
                  </td>

                <td>
                  <button className='btn' onClick={() => handleEditClick(item.journey_step_id)}>edit</button>
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: '80%', marginLeft: '-40%'}}>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Edit Journey</h2>

            <div className='mt-10'>
              <JourneyStepEdit journey_step_id={selectedId} setShowModal={setShowModal} loadData={loadData} />
            </div>
          </div>
        </>
      )}

    {showAdd && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: '80%', marginLeft: '-40%'}}>
            <div className='modal__close' onClick={() => setShowAdd(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Add new interview</h2>

            <div className='mt-10'>
              <JourneyStepAdd setShowModal={setShowAdd} loadData={loadData} />
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default JourneySteps;