import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getJourneyInterviews, getJourneys, getJourney, updateJourney, anthropicTest } from '../../utils/api/admin';

function AnthropicSandbox() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [results, setResults] = useState([]);
  const [description, setDescription] = useState('');
  const [prompt, setPrompt] = useState('');
  const [structuredPrompt, setStructuredPrompt ] = useState(`Only respond with valid JSON with the following schema: [{post: 'content goes here'}]. Do not include any other text, explanations or formatting.`);
  const [transcript, setTranscript] = useState('');
  const [analysisPrompt, setAnalysisPrompt] = useState(`You will be given a transcript of a call and the system prompt of the AI participant. Extract the candidate's name and generate 3 linkedin posts that are genuine, personalized, written in the first person, and focused on using the format and style of the highest engaging posts on linkedin. focus on learnings, convictions, beliefs, personal stories, life lessons, advice, though leadership, expertise. make it highly polarizing and opinionated content with a definitive point of view. Incorporate line breaks, bullet lists and emojis.`);
  const [imageUrl, setImageUrl] = useState('');
  //const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {

  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleRun(){
    let res =await anthropicTest(transcript, analysisPrompt, structuredPrompt);

    console.log('res', res);
    if (res.success){
      setResults(res.data);
      setLoaded(true);
    }
  }

  function renderContent(data) {
    if (typeof data === 'string' || typeof data === 'number') {
      // Render strings or numbers directly
      return <span>{data}</span>;
    } else if (Array.isArray(data)) {
      // Handle arrays by mapping over their contents
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{renderContent(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof data === 'object' && data !== null) {
      // Handle objects by rendering keys and values
      return (
        <div style={{ marginLeft: '10px', borderLeft: 'solid 1px #ddd', paddingLeft: '10px' }}>
          {Object.entries(data).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {renderContent(value)}
            </div>
          ))}
        </div>
      );
    }
  
    // Handle other data types (e.g., null, undefined)
    return <span>{String(data)}</span>;
  }
  

  return (
    <>
      <Helmet>
        <title>Journeys</title>
      </Helmet>

      <h1>Sandbox</h1>

      <div style={{
        display: 'flex',
        height: '440px',
        overflow: 'auto'
      }}>
        <div style={{flex: '1'}}>
          <strong>Transcript</strong>
          <textarea style={{height: '400px'}} id='txtPrompt' className='input form-control' placeholder='Prompt' value={transcript} onChange={(e) => setTranscript(e.target.value)}></textarea>
        </div>

        <div style={{flex: '1'}}>
          <strong>Prompt</strong>
          <textarea style={{height: '400px'}} id='txtPrompt' className='input form-control' placeholder='Analysis Prompt' value={analysisPrompt} onChange={(e) => setAnalysisPrompt(e.target.value)}></textarea>
        </div>

        <div style={{flex: '1'}}>
          <strong>Structure Prompt (in progress...)</strong>
          <textarea style={{height: '400px'}} id='txtPrompt' className='input form-control' placeholder='Structured Prompt' value={structuredPrompt} onChange={(e) => setStructuredPrompt(e.target.value)}></textarea>
        </div>
      </div>

      <div className='mt-10'>
        <button className='btn' onClick={() => handleRun()}>Run Prompt</button>
      </div>

      {loaded == true && results.length > 0 && (
        <div className='mt-30'>
          {results.map((item, index) => (
  <div
    key={index}
    style={{ padding: '10px', border: 'solid 1px', marginBottom: '10px', fontSize: '14px' }}
  >
    {renderContent(item.post)}
  </div>
))}

{/* 
          {results.map(item => (
            <div style={{padding: '10px', border: 'solid 1px', marginBottom: '10px', fontSize: '14px'}}>
              {item.post}
            </div>
          ))} */}
        </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default AnthropicSandbox;